.pq-container {
    padding: 1rem 6.2rem 0rem 5rem;
}

.apq {
    background-color: var(--secondarycolor);
    color: white;
    padding: 2rem 0rem;
}

.apq > .apq7 {
    padding: 6rem 4rem 0rem 0rem;
}

.apq > .apq7 > h3 {
    font-family: var(--secondaryfontfamily);
    font-size: 40px;
    padding: 10px 0px;
}

.apq4 {
    background-color: white;
    color: rgba(0, 7, 15, 0.49);
    border-radius: 8px;
    padding: 0px 20px;
}

.apq4 > div {
    padding: 20px 0px;
    line-height: 155%;
}

.apq4 > div > h6 {
    font-weight: 700;
    color: #00070F;
    margin-bottom: 1.5rem;
}

.apq4 > div > li {
    display: block;
    font-size: 14px;
}

.pq-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
    grid-gap: 35px;
    padding: 7rem 20px 0rem 20px;
}

.pastq-box {
    box-shadow: 0px 4px 17px rgba(11, 34, 57, 0.1);
    border-radius: 12px;
}

.pastq-box-img {
    border-radius: 12px;
    background-color: #E7F4F8;
    text-align: center;
}

.pastq-box-info {
    background-color: white;
    padding: 15px;
}

.pastq-box-info > p {
    margin-bottom: 0px;
    font-size: 15px;
}

.pastq-box-info > h6 {
    font-weight: 700;
}

.pastq-box-info > a {
    float: right;
    font-size: 12px;
    padding-bottom: 15px;
}

.pastq-box-info > a > img {
    padding-left: 5px;
}


.Paginationbtn {
    display: flex;
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
}

.pageli, .breakli {
    padding: 4px 11px;
    font-size: 14px;
    font-weight: 700;
    color: #7F8D96;
}

.prevbtn > svg, .nextbtn > svg {
    /* padding: 10px 15px; */
    font-size: 27px;
    font-weight: 700;
    text-decoration: none;
    color: inherit;
    fill: #0B2239;
    stroke: #0B2239;
}

.prevbtn:hover, .nextbtn:hover {
    /* padding: 10px 15px; */
    color: inherit;
}


.previous, .next {
    margin: -2px 0px;
    color: #0B2239;
    padding: 4px 11px;
}

.paginationactive {
    background-color: #002099;
    box-shadow: 0px 4px 15px rgba(17, 130, 128, 0.14);
    border-radius: 6px;
    color: #F8ECD7;
}

.paginationdisabled > .prevbtn > svg, .paginationdisabled > .nextbtn > svg{
    fill: #7F8D96;
    stroke: #7F8D96;
}

.selectbox {
    padding: 14px;
    border: 0.4px solid #002099;
    border-radius: 0px;
    height: 54px;
}

.selectbox1 {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

.searchbutton {
    padding: 15px 20px;
    background-color: black;
    /* border-radius: 4px; */
    border: 0px;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

@media only screen and (max-width: 1280px) {
    .pq-container {
        padding: 1rem 2rem 0rem 2rem;
    }
    
    .pq-grid {
        padding: 4rem 20px 0rem 20px;
    }
}

@media only screen and (max-width: 1024px) {
    .searchbutton {
        padding: 3px 20px !important;
        vertical-align: bottom;
    }
    
    .searchbutton > img {
        max-width: 80%;
    }

.selectbox {
    padding: 6px 1px !important;
    border: 0.4px solid #002099;
    font-size: 11px;

}

.pqselectbox {
    padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
}
}

@media only screen and (max-width: 992px) {
    .apq > .apq7 {
        padding: 6rem 3rem 0rem 3rem;
    }

    .apq4 {
        display: none;
    }
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {
    .pq-container {
        padding: 0px;
    }

    .pastq-box-info {
        background-color: white;
        padding: 15px 20px;
    }

    .apq > .apq7 {
        padding: 2rem 3rem 0rem 3rem;
    }

    .selectbox {
        padding: 14px;
        border: 0.4px solid #002099;
        border-radius: 0px;
        height: 30px;
    }
}

@media only screen and (max-width: 500px) {
    .page-link {
    padding: 0.35rem 0.3rem !important;
    }

    .apq > .apq7 {
        padding: 1rem 2rem 0rem 1rem;
    }

    .pq-container {
        padding: 0px;
    }

    .Paginationbtn {
        display: flex;
        list-style: none;
        padding-left: 0px;
        justify-content: center;
    }

    .previous {
        padding-left: 0px;
    }

    .next {
        padding-right: 0px;
    }

    .searchbutton {
        padding: 3px 8px !important;
        vertical-align: bottom;
        height: 29px;
    }

    .pqbutton > img {
        max-width: 50% !important;
    }
    .selectbox {
        padding: 14px;
        border: 0.4px solid #002099;
        border-radius: 0px;
        height: 29px;
    }
    
    .pqsbutton {
        height: 30px;
    }

    .pqbuttonimg {
        max-width: fit-content !important;
    }
}

