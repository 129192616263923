.mockexamgrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 20px 20px;
    padding-top: 20px;
}

.mockexambox {
    background: #FFFFFF;
    box-shadow: 0px 4px 17px rgba(11, 34, 57, 0.1);
    border-radius: 12px;
    text-align: center;
    padding: 30px 20px;
}

.mockexambox > h5 {
    font-weight: 700;
    font-size: 18px;
    text-align: left;
}

.mockexambox > div {
    display: flex;
    justify-content: space-between;
}

.mockexambox > div > p > span {
    font-size: 14px;
    font-weight: 600;
}

.mockexambox > div > p {
    text-align: left;
    padding-bottom: 16px;
    margin-bottom: 0px;

}

.successtext{
    color: #00BB29;
    font-weight: 600;
}

.mockexambox > div {
    text-align: center;
}

.mockexambox > div > a {
    border-radius: 12px;
    border: 0px;
    font-weight: 700;
    font-size: 13px;
    background-color: #1178FF;
    text-decoration: none;
    color: white;
    padding: 10px 15px;
}

.mockexambox > div > a:hover {
    color: white;
}

.leaderboard {
    background-color: white !important;
    border: 1px solid var(--primarycolor);
    color: var(--primarycolor) !important;
}

.paginate {
    display: flex;
    justify-content: space-between;
}

.mockexamcomp {
    background: #FFFFFF;
    box-shadow: 0px 4px 17px rgba(11, 34, 57, 0.1);
    border-radius: 12px;
    padding: 3rem;
    margin: 6rem;
}

.mockexamcompheader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.mockexamcompheader > h3 {
    font-weight: 700;
    font-size: 24px;
}

.mockexamcompheader > select {
    padding: 10px 35px 10px 15px;
    border: 1px solid #00070F;
    border-radius: 28px;
    font-size: 14px;
    width: auto;
}

.mockexamcompheader > select:focus-visible {
    outline: #00070F auto 1px;
}

.mockexamcompheader >  select:not(:-internal-list-box) {
    overflow: unset !important;
}

.mockexamlist > li {
    color: var(--primarycolor);
}

.mockexamlist > li > span {
    color: rgba(0, 7, 15, 0.97);;
    font-size: 14px;
    vertical-align: middle;
}

.tyav {
    background: rgba(217, 217, 217, 0.1);
    border-radius: 12px;
    padding: 15px;
}

.tyav > div > p {
    margin-bottom: 0px;
    padding: 10px;

}

.tyav > div > p > span {
    font-weight: 700;
}

.comingsooncomp {
    display: flex;
}

.comingsooncomp > div > svg {
    width: 710px;
    height: 744px;
    margin-top: -5rem;
}

.notified {
    margin: auto;
}

.notified > h1 {
    font-weight: 800;
}

.notified > p:nth-child(1) {
    font-size: 18px;
}

.notified > p {
    font-size: 12px;
}

.mockexamcomp > div > a {
    background-color: var(--primarycolor);
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    font-weight: 800;
    font-size: 16px;
    color: white;
    padding: 12px 25px;
}

.filterbox {
    display: flex;
    justify-content: flex-end;
}

.filterbox > select {
    width: auto;
    border-radius: 0px;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
}

.filterbox > select:focus-visible, .filterbox > select:focus {
    outline: none;
    border-color: #ced4da;
    box-shadow: none;
}

.filterbox > button {
    background-color: var(--primarycolor);
    color: white;
    font-weight: 700;
    border: 0px;
    padding: 0px 15px;
    font-size: 14px;
    border-radius: 0px 4px 4px 0px;
}

.paginate > p {
    font-weight: 500;
    font-size: 12px;
    color: #7F8D96;
}

@media only screen and (max-width: 1280px) {
    .comingsooncomp > div > svg {
        width: 575px;
        height: 744px;
        margin-top: -7rem;
    }
}

@media only screen and (max-width: 1024px) {
    .mockexamcomp {
        padding: 2rem;
        margin: 2rem;
    }

    .mockexamcompheader > h3 {
        font-weight: 700;
        font-size: 20px;
    }

    .mockexamcompheader > select {
        padding: 5px 35px 5px 15px;
    }

    .mockexamlist > li > span {
        color: rgba(0, 7, 15, 0.97);
        font-size: 12px;
    }

    .comingsooncomp > div > svg {
        width: 430px;
        height: 603px;
        margin-top: -7rem;
    }

    .notified > h1 {
        font-weight: 800;
        font-size: 25px;
    }
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {
    .mockexamcomp {
        padding: 1.2rem;
        margin: 0rem;
    }


    .mockexamcompheader > h3 {
        font-weight: 700;
        font-size: 15px;
    }

    .mockexamcompheader > select {
        padding: 0px 29px 0px 10px;
    }

    .tyav > div > p {
        font-size: 13px;
    }

    .filterbox > button {
        padding: 0px 8px;
        font-size: 11px;
    }

    .filterbox > select {
        height: 35px;
        width: fit-content;
        font-size: 7px;
    }

    .searchbutton > img {
        max-width: 70%;
    }

    .comingsooncomp {
        display: block;
    }

    .notified {
        margin: auto;
        text-align: center;
    }
}

@media only screen and (max-width: 500px) {
    .filterbox > select {
        /* width: auto; */
        border-radius: 0px;
        font-size: 8px;
        padding-top: 7px;
        padding-bottom: 10px;
    }

    .form-select {
        padding: 0.275rem 1.6rem 0.275rem 0.2rem !important;
        height: 30px !important;
        background-position: right 0.5rem center !important;
        background-size: 12px 12px !important;
    }

    .filterbox > button {
        padding: 0px 5px;
        font-size: 9px;
    }

    .mockexamcompheader > h3 {
        font-weight: 700;
        font-size: 13px;
    }

    .mockexamcompheader > select {
        font-size: 10px;
    }

    .mockexamlist > li > span {
        font-size: 9px;
    }

    .tyav > div > p {
        font-size: 10px;
    }

    .mockexamcomp > div > a {
        font-size: 12px;
    }

    .filterbox1 {
        margin: 0px -16px;
    flex-wrap: wrap;
    }

    .filterbox1 > button {
        padding: 6px 10px;
        font-size: 9px;
        /* margin-top: 10px; */
        border-radius: 4px;
    }

    .mockexamcomp {
        margin-top: 2rem;
    }
    .mockexamcomp > div > a {
        padding: 12px 13px;
    }

    .comingsooncomp > div > svg {
        width: 320px;
        height: 465px;
        margin-top: -7rem;
    }

    .comingsooncomp {
        display: block;
        padding: 2rem 0rem;
    }
}