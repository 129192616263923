.footer {
    background-color: #00070F;
    padding: 3.5rem 3.5rem 1.5rem 3.5rem;
    margin-top: 7rem !important;
}

.footer > div > div > h1 {
    color: white;
    font-weight: 700;
    font-size: 22px;
}
.footer > div > div > a{
    display: block;
    text-decoration: none;
    color: white;
    font-size: 16px;
}

.footer-logo {
    margin-top: 3.5rem !important;
}
.footer-logo > p {
    color: #FFFFFF;
}

.footer-icon {
    border: 0.5px solid white;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: inline-block;
    margin: 12px;
}

.footer-end {
    background-color: var(--secondarycolor);
    padding: 1.7rem 5rem;
    color: white;
    font-size: 15px;
}