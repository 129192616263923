.generateexam-container {
    box-shadow: 0px 4px 20px rgba(0, 7, 15, 0.1);
    border-radius: 16px;
    width: 600px;
}

.generateform {
    padding: 3rem 3rem;
}

.generate-content {
    width: 80%;
    display: flex;
    float: right;
    justify-content: space-between;
    margin: 3rem 0rem;
}

.generate-select > select {
    width: 100%;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    height: 35px;
    padding: 0px 10px;
    font-size: 14px;
}

.generateform > div {
    margin: 1.15rem 0rem;
}

.generate-radio {
    display: flex;
    justify-content: center;
    margin: 0rem !important;
}

.generate-radio > div {
    display: flex;
    margin: 0rem 0.6rem;
}

.generate-radio > div > input {
    width: auto;
    appearance: none;
    height: 12px;
    margin: auto;
}

.generatebutton {
    border-radius: 4px;
    background-color: #1178FF;
    padding: 0.5rem 2rem;
    border: 0px;
    color: white;
}

.generate-button-container {
    display: flex;
    justify-content: center;
}

.generate-radio > div > input[type="radio"]:checked {
    background-color: #1178FF;
}

.generate-radio > div > label {
    margin: auto 0rem;
    padding-bottom: 0rem;
    padding-left: 0.5rem;
} 

.generate-popup {
    font-weight: 700;
    margin-bottom: 0rem;
}

.exam-type-text {
    color: #1178FF;
    font-size: 14px;
    text-align: center;
    margin-bottom: 0px;
}

.generate-borderline {
    background-color: #1178FF;
    border-radius: 2px 2px 8px 8px;
    width: 100%;
    display: block;
    content: '';
    height: 6px;
    margin-top: 2rem;
}

@media only screen and (max-width: 600px) {
 
    .generate-content {
     width: 100;
    }

    .generate-content {
        width: 100%;
        display: block;
        float: right;
        justify-content: space-between;
        margin: 3rem auto;
    }

    .generate-time {
       display: none;
    }

    .generateexam-container {
        box-shadow: 0px 4px 20px rgb(0 7 15 / 10%);
        border-radius: 16px;
        width: 480px;
    }
 
 }
 
 @media only screen and (max-width: 500px) {
     .generateexam-container {
         box-shadow: 0px 4px 20px rgb(0 7 15 / 10%);
         border-radius: 16px;
         width: 325px;
     }
 
     .generate-content {
         width: 100%;
         display: block;
         float: right;
         justify-content: space-between;
         margin: 3rem auto;
     }

     .generate-time {
        display: none;
     }

     .generateform {
        padding: 1.5rem 1.5rem;
    }

    .exam-type-text  {
        font-size: 11px;
    }

    .generate-radio {
        margin: 1.15rem 0rem !important;
    }
 
 }