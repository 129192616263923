.dashboardboxcontainer {
    display: grid;
  grid-template-columns: 220px 274px 245px auto;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(254px, 1fr));
}

.dashboardbox {
    box-shadow: 0px 4px 20px rgba(11, 34, 57, 0.12);
    border-radius: 8px;
    padding: 20px 20px 20px 20px;
    /* margin: 0px 5px; */
    position: relative;
    height: 165px;
}

.dashboardbox > h6 {
    font-weight: 700;
    font-size: 16px;
}

.dashboardbox > p {
    font-weight: 300;
    font-size: 12px;
    color: rgba(28, 10, 0, 0.65);
}

.dashboardbox > span {
    font-weight: 700;
    font-size: 20px;
    color: rgba(0, 7, 15, 0.45);
    position: absolute;
    bottom: 0;
    margin-bottom: 15px;
}

.dashboardbox > span > p {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0px;
}

.dashboardbox > span > a {
    font-weight: 400;
font-size: 12px;
}

.maincontainerexpand > .content > .dashboardcontent > .dashboardboxcontainer {
    display: grid;
    grid-template-columns: 220px 274px 245px auto;
    gap: 30px;
}

.maincontainerexpand > .content > .dashboardcontent > .dashboardboxcontainer > .dashboardbox {
    height: auto;
}

.maincontainerexpand > .content > .dashboardcontent > .shortcutcontainer > .shortcutboxcontainer {
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 30px;
}

.dashboardcontent {
    padding-top: 3rem;
}

.dashboardtitle {
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
    margin: auto 0px;
}

.daycard {
    border: 1px solid #6A6E72;
    border-radius: 12px;
    width: 65px;
    padding: 15px;
}

.update-dashboardbox:nth-child(2) {
    background-color: #F1F1F1;
}

.daycard > p {
    text-align: center;
    margin: 0px;
}

.daycard > p:nth-child(2) {
    text-align: center;
    font-weight: 700;
}

.carousel-indicators {
    bottom: -25px !important;
    margin-bottom: 0px !important;
}

.carousel-item {
    border: 0px;
}
.carousel-indicators > .active {
    background-color: #A3A3A3;;
}

.update-dashboardboxcontainer {
    grid-template-columns: repeat(auto-fit, minmax(315px, 1fr)) !important;
    justify-content: space-between;
}

.update-dashboardbox {
    height: auto !important;
    background-color: #7A8FFF;
}

.carousel-control-next, .carousel-control-prev {
    display: none !important;
}

.stat-info {
    display: flex;
    margin-top: 25px;
}

.stat-info > span {
    width: 50%;
    display: block;
    font-weight: 700;
    font-size: 44px;
    text-align: center;
}

.stat-info > span:nth-child(2) {
    font-size: 11px;
    text-align: left;
    font-weight: 400;
    margin: 10px 0px;
    padding: 0px 10px;
    align-self: center;
    border-left: 0.6px solid rgba(0, 7, 15, 0.31);
}

.dash-img {
    margin: 10px 15px 25px;
}

.dash-practice {
    text-decoration: none;
    color: white;
    background-color: #5B75FF;
    border-radius: 24px;
    padding: 10px 30px;
    font-weight: 700;

}

.overal-total > span > span {
    content: '';
    width: 13px;
    height: 13px;
    display: block;
    border-radius: 2px;
    background-color: white;
    align-self: center;
    margin-right: 15px;
}

.total-score > span > span {
    background-color: #FFF27A;
}

.overal-total {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    border-bottom: 0.7px solid rgba(255, 255, 255, 0.26);
    padding: 5px 0px;
}

.overal-total > span {
    color: white;
    display: flex;
}

.news {
    font-weight: 700;
    font-size: 22px;
    text-transform: capitalize;
}

.news > span {
    content: '';
    width: 11px;
    height: 11px;
    background-color: #7A8FFF;
    display: inline-block;
    border-radius: 25px;
    margin-right: 15px;
}

.dash-practice:hover {
    color: white;
}

.shortcutboximg > img {
    max-width: 100%;
}

.shortcutbox > h6 {
    text-align: left;
    padding: 15px 0px;
    border-bottom: 0.7px solid rgba(0, 7, 15, 0.21);;
}

.shortcutcontainer {
    margin-top: 4rem;
}

.shortcutboxcontainer {
    display: grid;
    grid-template-columns: 316px 316px 316px ;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    
}

.shortcut-filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.shortcutbox {
    box-shadow: 0px 4px 20px rgba(11, 34, 57, 0.12);
    border-radius: 8px;
    padding: 15px;
    text-align: center;
}

.shortcutbox > span {
    background-color: #1178FF;
    float: right;
    padding: 10px 15px;
    text-align: center;
    border-radius: 50%;
}

/* .shortcutbox > div {
    background-color: #1178FF;
    margin: 0px -15px -15px;
    padding: 10px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    font-weight: 700;
    font-size: 16px;
} */

.shortcutbox > .shortcutboximg {
    background-color: white;
    text-align: center;
    margin: auto;
}

.newsicons > div {
    background-color: rgba(17, 120, 255, 0.08);
    border-radius: 25px;
    width: 25px;
    height: 25px;
    display: grid;
    justify-content: center;
}

.newsicons > div > img {
    align-self: center;
}

.newsicons {
    text-align: left;
    display: flex;
}

.newsicons > span, .newsicons > a {
    /* padding-right: 10px; */
    color: rgba(0, 7, 15, 0.51);
    font-size: 11px;
    text-decoration: none;
    align-self: center;
}

.update-shortcutbox {
    border-radius: 24px !important;
}

.shortcutbox > div > a {
    font-weight: 700;
    font-size: 16px;
    color: white;
    text-decoration: none;
}

.welcome-header {
    border: 0px !important;
}

.welcome-message {
    text-align: center;
    padding: 1.5rem !important;
}

.welcome-message > div > h4 {
    font-weight: 700;
    font-size: 1.5rem;
    padding: 1rem;
    color: #00A82F;
}



@media only screen and (max-width: 1280px) {
    .maincontainerexpand > .content > .dashboardcontent > .dashboardboxcontainer {
        display: grid;
        grid-template-columns: 220px 274px 245px auto;
        gap: 30px;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

.update-dashboardboxcontainer {
    grid-template-columns: repeat(auto-fit, minmax(285px, 1fr)) !important;
}

}

@media only screen and (max-width: 1024px) {
    .maincontainerexpand > .content > .dashboardcontent > .dashboardboxcontainer > .dashboardbox {
        height: 155px;
    }
}

@media only screen and (max-width: 992px) {
    
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 500px) {
    .content {
        padding: 0rem 2rem 0rem;
    }

    .shortcut-filter {
        display: block;
    }

    .shortcut-filter > .filterbox {
        justify-content: flex-start;
        margin: opx;
    }

    .dashboardtitle {
        margin: 0.5rem 0rem 0.75rem;
    }

    .welcome-message > div > h4 {
        padding: 1.2rem 0rem;
        margin: 0rem;
    }
}