.faq-container {
    padding: 1rem 6.2rem 0rem 5rem;
}

.wdpua {
    background-color: var(--secondarycolor);
    color: white;
}

.wdpua > div {
    padding: 7rem 4rem 7rem;
}

.wdpua > div > h3 {
    font-family: var(--secondaryfontfamily);
    font-size: 40px;
    padding: 10px 0px;
}


.accordion-button:not(.collapsed) {
    background-color: white !important;
    color: black !important;
    /* border-bottom: 1px solid #dee2e6; */
}

.accordion-item {
    border: 0px !important;
}

.accordion-button::after {
    color: black !important;
}

.accordion {
    --bs-accordion-btn-focus-border-color: none !important; 
    --bs-accordion-btn-focus-box-shadow: none !important;
}

.accordion-button {
    background: #FFFFFF;
    box-shadow: 0px 4px 17px rgba(0, 7, 15, 0.13) !important;
    border-radius: 4px !important;
    border: 0px !important;
    font-weight: 700;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon) !important;
}

.accordion-body {
    box-shadow: 0px 4px 17px rgb(0 7 15 / 13%) !important;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.accord {
    padding-top: 5rem;
}

.accord-title {
    font-weight: 700;
}

.accord > div {
    margin-bottom: 1.5rem;
}

.gelnf {
    margin-top: 5rem !important;
}

.gelnf > h3 {
    font-size: 30px;
    font-family: var(--secondaryfontfamily);
    font-weight: 600;
    text-transform: capitalize;
}

.gelnf > span {
    text-align: center;
    border-bottom: 1px solid #5C5C5C;
    width: 60px;
    margin: 0px auto;
}

.faq-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    grid-gap: 70px;
    padding: 3.5rem 20px 0rem 20px;
}

.faq-grid > div > div > img {
    max-width: 100%;
}

.faq-grid > div > h5 {
    font-size: 25px;
    text-transform: capitalize;
    font-weight: 700;
    color: #1C0A00;
    padding-top: 25px;
}

@media only screen and (max-width: 1280px) {
    .faq-container {
        padding: 1rem 2rem 0rem 2rem;
    }
    
}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 992px) {
    .faq-container {
        padding: 1rem 1rem 0rem 1rem;
    }

    .faq-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media only screen and (max-width: 768px) {
    .faq-button {
        margin: 0px;
    }
}

@media only screen and (max-width: 600px) {
    .faq-container{
        padding: 0px;
    }

    .wdpua > div {
        padding: 4rem 2rem 4rem;
    }
}

@media only screen and (max-width: 500px) {
}