.Navbar-container {
    height: 4rem;
    margin: 0px 3.5rem 0px 3.5rem !important;
}

.Navbar-menu {
    display: flex;
    float: right;
    padding: 15px 0px;
}

.Navbar-menu > a {
    padding: 3px 10px;
    text-decoration: none;
    color: var(--secondarycolor);
}

.regbutton {
    background-color: var(--primarycolor);
    color: white !important;
    padding: 3px 10px !important;
    border-radius: 4px;
}

.loginbutton {
    border: 1px solid var(--primarycolor);
    border-radius: 4px;
    color: var(--primarycolor) !important;
    padding: 3px 13px !important;
    margin: 0px 10px;
}

.Navbar-menu {
    display: flex;
    float: right;
    padding: 15px 0px;
    font-size: 14px;
}

.mobile-menu {
    display: none !important;
}

@media only screen and (max-width: 1280px) {
    .Navbar-container {
        margin: 0px 0.5rem 0px 0rem !important;
    }
    
}

@media only screen and (max-width: 1024px) {
    .Navbar-menu {
        font-size: 14px;
    }
}

@media only screen and (max-width: 600px) {
    .Navbar-container {
        display: none !important;
    }


.hamburger {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #cdcdcd;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
}

.hamburgerX1  {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
}

.hamburgerX2 {
    transform: rotate(-45deg) translate(0, -1px);
    transform-origin: 0% 100%;
    background: #232323;
}

.hamburgerX3  {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}



.hamburger-container {
    padding: 15px 0px 15px 30px;
}

.show1 {
    height: auto;
    background-color: #002099;
    width: 500px;
    color: white;
    /* padding: 15px; */
    z-index: 99;
    display: block !important;
    margin-top: -55px !important;
    transition: all .5s ease-in-out;
    padding: 0px 0px 20px;
}

.show1 > li {
    display: block;
    padding: 15px 15px 5px;
}

.show1 > li > a{
   text-decoration: none;
   color: white;
}

.show1 > .mobile-button {
    padding-bottom: 10px;
}

.hide1 {
    display: none;
}

.mobile-img-box > a > svg {
    width: 150px;
    height: 99px;
}

.menureg {
    text-decoration: none;
    padding: 6px 10px !important;
}

.menulogin {
    text-decoration: none;
    margin: 0px;
    padding: 6px 19px !important;
}

.mobile-menu {
    height: 50px;
    border-bottom: 1px solid #cdcdcd;
    display: flex !important;
}
}

@media only screen and (max-width: 500px) {
    .hamburger-container {
        padding: 15px 0px 15px 15px;
    }

    .mobile-img-box > a > img {
        max-width: 150%;
        margin-left: -10px;
    }

    .show1 {
        margin-top: -55px !important;
        padding: 0px 0px 20px;
    }

    .mobile-menu {
        height: 50px;
        border-bottom: 1px solid #cdcdcd;
    }
}

