.modaltab {
justify-content: space-around;
padding: 2rem 0rem;
}

.modaltab1 {
    justify-content: center !important;
    padding: 0rem !important;
}

.modaltab > div {
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    height: max-content;
}

.tran-history {
    text-decoration: none;
    border-radius: 8px;
    padding: 10px 15px;
    background-color: blue;
    color: white;
    font-weight: 700;
    margin-top: 16px;
    display: inline-block;
}

.h12 {
    color: #002099;
    text-align: center;
    font-family: sans-serif;
    text-transform: capitalize;
    font-size: 14px !important;
    padding: 0.15rem 0rem;
    font-weight: 500 !important;
    position: relative;
}

.h14 {
    text-align: center;
    font-family: sans-serif;
    text-transform: capitalize;
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin: 0rem;
    position: relative;
    padding: 0.15rem 0rem;
}

.account1 {
    font-weight: 500 !important;
    font-size: 14px !important;
    padding: 0.15rem 0rem;
}

.h12:after, .h14:after {
    position: absolute;
    content: "";
    -webkit-animation: Dots 2s cubic-bezier(0, .39, 1, .68) infinite;
    animation: Dots 2s cubic-bezier(0, .39, 1, .68) infinite;
}

.modaltab > div > div > p {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 0px;
}

.pricetag{
    font-weight: 400 !important;
    font-size: 14px !important;
    color: rgba(0, 7, 15, 0.33);
}

.modaltab > form > input {
    border: 0px;
    border-bottom: 1px solid #D9D9D9;
    border-radius: 0px;
}

.modaltab > form > label {
    font-weight: 600;
    font-size: 14px;
    color: #747474;
    padding-top: 25px;
}

.modaltab > form > div > div > input {
    border: 0px;
    border-bottom: 1px solid #D9D9D9;
    border-radius: 0px;
}

.modaltab > form > div > div > label {
    font-weight: 600;
    font-size: 14px;
    color: #747474;
    padding-top: 25px;
}

.modaltab > form > div > .cvc {
    padding-left: 20px;
}

.modalcheckpoint {
    padding-top: 20px;
}

.modalcheckpoint > input{
    width: auto;
    height: auto;
}

.modalcheckpoint > span {
    padding-left: 15px;
    font-weight: 400;
    font-size: 14px;
}

.modaltab >form > button {
    border: 0px;
    width: 100%;
    color: white;
    font-weight: 800;
    font-size: 16px;
    background-color: #0064E5;
    border-radius: 4px;
    padding: 10px 0px;
    margin-top: 25px;
}

.modal-footer {
    justify-content: center !important;
}

.transferinfo {
    background-color: #F7FAFF;
    border: 1px solid #EEE9E8;
    border-radius: 2px;
    padding: 1rem 2rem;
}

.transferinfo > div {
    padding: 15px 0px;
}

.transfercontainer {
    border: 0px !important;
}

.transfercontainer  > p {
    font-weight: 400;
    font-size: 16px;
    color: #5E5E5E;
}

.transferinfo > div >  p {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 12px;
    color: #5E5E5E;
    text-transform: uppercase;
}

.transferinfo > div > div >  p {
    font-weight: 700;
    font-size: 16px;
    color: black;
    margin-bottom: 0px;
} 

.transferinfo > div > div > span{
    border-radius: 2px;
    background-color: #0064E5;
    font-weight: 700;
    font-size: 8px;
    color: white;
    padding: 1px 7px;
    margin: 5px 7px;
    vertical-align: middle !important;
    height: max-content;
    cursor: pointer;
} 

.transfercontainer > button {
    border: 0px;
    width: 100%;
    color: white;
    font-weight: 800;
    font-size: 16px;
    background-color: #0064E5;
    border-radius: 4px;
    padding: 10px 0px;
    margin-top: 25px;
}

.modaltab > form > p {
    font-weight: 500;
    font-size: 14px;
    color: #404040;
}

.modaltab > form > div > input, .modaltab > form > div > select {
    border: 0px;
    border-bottom: 1px solid #D9D9D9;
    border-radius: 0px;
}

.modaltab > form > div > input:focus-visible, .modaltab > form > div > select:focus-visible, .modaltab > form > input:focus-visible, .modaltab > form > div > div > input:focus-visible {
    border: 0px;
    outline: 0px;
    border-bottom: 1px solid #D9D9D9;
}

.arrow-cursor {
    cursor: pointer;
}

.res-mesg {
    padding: 2rem;
}

.res-mesg > h3 {
    font-weight: 700;
    font-size: 48px;
}

.res-mesg > p {
    font-weight: 400;
font-size: 14px;
color: #404040;
}

.successcolor > svg > g > .respcolor {
    fill: #00BB29;
}

.errorcolor > svg > g > .respcolor {
    fill: #E32900;
}

.copy {
    font-weight: 700;
    font-size: 10px;
    color: var(--primarycolor);
    border: 1px solid #1178FF;
    border-radius: 2px;
    padding: 7px 30px;
    cursor: pointer;
}

.copy > span {
    padding-left: 10px;
}

.pricetab {
    display: none;
}

.process {
    border: 0px !important;
    text-align: center;
}

.process > h1 {
    font-weight: 700;
    color: #F9DD20;
    font-size: 40px;
}

.process > p {
    padding: 1rem 6rem 0rem;
}

.process > p > a {
    color: #002099;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 992px) {
    .modal-dialog {
        max-width: 95% !important;
    }

    .modaltab > div {
        padding: 10px 15px;
    }
}

@media only screen and (max-width: 768px) {
    .modal-dialog {
        max-width: 95% !important;
    }

    .pricetag {
        font-weight: 400 !important;
        font-size: 12px !important;
    }
}

@media only screen and (max-width: 600px) {
    .psboxtitle > p {
        padding: 15px;
        margin: 0px 6px;
        font-size: 14px !important;
    }

    .modaltab {
        justify-content: space-around;
        padding: 1rem 0rem;
    }

    .modaltab > form > label, .modaltab > form > div > div > label {
        padding-top: 8px;
    }

    .modalcheckpoint > span {
        padding-left: 10px;
        font-weight: 400;
        font-size: 12px;
    }

    .transfercontainer > p {
        font-size: 13px;
    }

    .transferinfo {
        padding: 1rem 1rem;
    }

    .transferinfo > div > p {
        font-size: 10px;
    }

    .transferinfo > div > div > p {
        font-weight: 700;
        font-size: 14px;
    }

    .transfercontainer > button {
        font-size: 13px;
    }
    .modaltab > form > p {
        font-size: 11px;
    }

    .modaltab > form > div > input, .modaltab > form > div > select {
        font-size: 10px;
    }

    .modaltab >form > button {
        font-size: 13px;
    }

    .copy {
        font-size: 8px;
        padding: 7px 15px;
    }

    .modaltab1 {
        justify-content: center !important;
        padding: 0rem !important;
    }

    .subscriptionboxplangrid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
        gap: 20px;
        padding-top: 30px;
    }

    .modaltab > form > input {
        height: 35px;
    }

    .pricebox {
        display: none;
    }

    .pricetab {
        border: 1px solid #D9D9D9;
        border-radius: 6px;
        height: max-content;
        display: block;
        margin-top: 25px;
        padding: 10px;
    }

    .pricetab > div > p {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 500px) {
    .psboxtitle > p {
        padding: 10px 0px;
        margin: 0px 10px;
    font-size: 11px !important;
    }

    .transferinfo > div > div > p {
        font-weight: 700;
        font-size: 11px;
    }

    .transferinfo > div {
        padding: 8px 0px;
    }

    .pricetag {
        font-weight: 400 !important;
        font-size: 10px !important;
    }

    .transfercontainer > button {
        font-size: 10px;
    }

    .modaltab > div > div > p {
        font-size: 18px;
    }

    .transferinfo > div > div > span {
        font-size: 6px;
    }

    .modaltab > form > p {
        font-size: 9px;
    }

    .subscriptionboxplan > div, .activesubplan > div  {
        text-align: center;
    }

    .modalcheckpoint > span {
        padding-left: 10px;
        font-weight: 400;
        font-size: 10px;
    }

    .pricebox {
        display: none;
    }

    .pricetab {
        border: 1px solid #D9D9D9;
        border-radius: 6px;
        height: max-content;
        display: block;
        margin-top: 25px;
        padding: 10px;
    }

    .pricetab > div > p {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 0px;
    }

    .psboxtitle > p > svg {
        width: 11px;
    }

    .psboxtitle > p > span {
        vertical-align: middle;
        padding-left: 5px !important;
    }

    .psboxtitle > p {
        padding: 10px 0px;
        margin: 0px 8px !important;
        font-size: 11px !important;
    }
}


