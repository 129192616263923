.current-point {
    text-align: center;
}

.current-point > h1 {
    font-size: 64px;
    font-weight: 700;
    color: #1178FF;
    margin: 0px;
}

.current-point > h1 > span {
    font-size: 32px;
    font-weight: 500;
}

.current-point > p {
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
    color: rgba(0, 7, 15, 0.63);
}

.progress-container {
    background: rgba(135, 189, 252, 0.06);
    backdrop-filter: blur(20px);
    border-radius: 12px;
    padding: 3rem 2rem 1rem;
}

.progress-container > div {
    padding: 2rem 0rem;
}

.claim-airtime >  button {
    background: #1178FF;
    border-radius: 8px;
    color: white;
    font-weight: 700;
    text-transform: capitalize;
    display: block;
    border: 0px;
    margin: auto;
    padding: 0.75rem 2rem;
}

.claim-instruction {
    text-align: center;
    font-size: 15px;
    padding: 2rem;
}

.claim-instruction > p {
    margin: 0rem;
    color: #404051;
}

.claim-instruction > p:nth-child(2) {
    color: #8A8A9E;
}

.claim-instruction > p > span {
    font-weight: 700;
}

.claim-list {
    display: flex;
    background: rgba(135, 189, 252, 0.06);
backdrop-filter: blur(20px);
border-radius: 12px;
padding: 1rem ;
justify-content: space-around;
font-size: 15px;
margin: 1.5rem 0rem;
}

.claim-list > div > span:nth-child(2) {
    font-weight: 600;
}

.claim-info {
    padding: 1rem 18rem;
    text-align: center;
    font-size: 15px;
    color: #8A8A9E;
}

.vertical-line {
    border-right: 2px solid #D1D1D6;
    content: '';
    /* width: 100%; */
    height: 40px;
    padding: 0px !important;
}

.ten-percent {
    flex: 0 0 auto;
    width: 9.69% !important;
}

.thirty-percent {
    flex: 0 0 auto;
    width: 19.35% !important;
}

.fifty-percent {
    flex: 0 0 auto;
    width: 19.35% !important;
}

.seventy-percent {
    flex: 0 0 auto;
    width: 19.35% !important;
}

.hundred-percent {
    flex: 0 0 auto;
    width: 28.7% !important;
}

.vertical-box {
        text-align: end;
    margin-left: 22.5px;
    padding: 0px !important;
}

.vertical-box-3 {
    margin-left: 0px;
}

.vertical-box-4 {
    flex: 0 0 auto;
    width: 29.25% !important;
    margin-left: 0rem;
    padding: 0px !important;
}

.progress-set {
    padding: 0rem 2rem 0rem 1rem;
}

.vertical-box > span {
    background-color: #D1D1D6;
    border-radius: 4px;
    color: white;
    padding: 5px 10px;
}

.progress-indicator-sett {
    padding-left: 1rem;
}

.notover {
    border-right: 2px solid #1178FF !important;
}

.notoverbox > span {
    background-color: #1178FF !important;
}

.claim-option-tab {
    display: grid;
    gap: 1.5rem;
    padding: 2rem 5rem;
    grid-template-columns: 220px 274px 245px;
    grid-template-columns: repeat(auto-fit, minmax(254px, 1fr));

}

.option-tab {
    border: 1px solid rgba(15, 5, 7, 0.13);
border-radius: 24px;
text-align: center;
padding: 2.5rem 3rem;
align-items: center;
display: grid;
font-weight: 700;
color: rgba(15, 5, 7, 0.4);
cursor: pointer;
}

.activeoption:hover {
    color: #1178FF;
    border: 1px solid #1178FF;
}

.claim-info-2 {
    padding-top: 4rem;
}

.claim-info-2 > p {
    margin: 0rem;
    color: #404051;
}

.claim-info-2 > p > span {
    font-weight: 700;
}

@media only screen and (max-width: 1280px) {
    .claim-info {
        padding: 1rem 11rem;
    } 
    
    .ten-percent {
        flex: 0 0 auto;
        width: 9.6% !important;
    }
    
    .thirty-percent {
        flex: 0 0 auto;
        width: 19.24% !important;
    }
    
    .fifty-percent {
        flex: 0 0 auto;
        width: 19.24% !important;
    }
    
    .seventy-percent {
        flex: 0 0 auto;
        width: 19.22% !important;
    }
    
    .hundred-percent {
        flex: 0 0 auto;
        width: 28.4% !important;
    }

    .vertical-box-4 {
        flex: 0 0 auto;
        width: 29% !important;
        margin-left: 0rem;
        padding: 0px !important;
    }

    .claim-option-tab {
        padding: 2rem 3rem;
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    }
}

@media only screen and (max-width: 1024px) {
    .claim-list {
        font-size: 11px;
    }

    .claim-info {
        font-size: 10px;
    }

    .claim-instruction {
        font-size: 13px;
        padding: 2rem 2rem 0.5rem;
    }

    .vertical-box > span {
        font-size: 13px;
    }

    .ten-percent {
        flex: 0 0 auto;
        width: 9.5% !important;
    }
    
    .thirty-percent {
        flex: 0 0 auto;
        width: 18.9% !important;
    }
    
    .fifty-percent {
        flex: 0 0 auto;
        width: 19% !important;
    }
    
    .seventy-percent {
        flex: 0 0 auto;
        width: 19% !important;
    }
    
    .hundred-percent {
        flex: 0 0 auto;
        width: 27.6% !important;
    }

    .vertical-box-4 {
        flex: 0 0 auto;
        width: 28% !important;
        margin-left: 0rem;
        padding: 0px !important;
    }
}

@media only screen and (max-width: 992px) {
    .ten-percent {
        flex: 0 0 auto;
        width: 9.5% !important;
    }
    
    .thirty-percent {
        flex: 0 0 auto;
        width: 19.1% !important;
    }
    
    .fifty-percent {
        flex: 0 0 auto;
        width: 19.1% !important;
    }
    
    .seventy-percent {
        flex: 0 0 auto;
        width: 19.1% !important;
    }
    
    .hundred-percent {
        flex: 0 0 auto;
        width: 27.6% !important;
    }

    .vertical-box-4 {
        flex: 0 0 auto;
        width: 28% !important;
        margin-left: 0rem;
        padding: 0px !important;
    }
    
}

@media only screen and (max-width: 820px) {
    .ten-percent {
        flex: 0 0 auto;
        width: 9.5% !important;
    }
    
    .thirty-percent {
        flex: 0 0 auto;
        width: 18.9% !important;
    }
    
    .fifty-percent {
        flex: 0 0 auto;
        width: 19% !important;
    }
    
    .seventy-percent {
        flex: 0 0 auto;
        width: 18.95% !important;
    }
    
    .hundred-percent {
        flex: 0 0 auto;
        width: 27.6% !important;
    }

    .vertical-box-4 {
        flex: 0 0 auto;
        width: 28% !important;
        margin-left: 0rem;
        padding: 0px !important;
    }
    
}

@media only screen and (max-width: 768px) {
    .ten-percent {
        flex: 0 0 auto;
        width: 9.5% !important;
    }
    
    .thirty-percent {
        flex: 0 0 auto;
        width: 18.8% !important;
    }
    
    .fifty-percent {
        flex: 0 0 auto;
        width: 18.85% !important;
    }
    
    .seventy-percent {
        flex: 0 0 auto;
        width: 18.8% !important;
    }
    
    .hundred-percent {
        flex: 0 0 auto;
        width: 27.6% !important;
    }

    .vertical-box-4 {
        flex: 0 0 auto;
        width: 28% !important;
        margin-left: 0rem;
        padding: 0px !important;
    }
    
}

@media only screen and (max-width: 600px) {
    .claim-info {
        padding: 1rem 3rem;
    }

    .ten-percent {
        flex: 0 0 auto;
        width: 9.6% !important;
    }
    
    .thirty-percent {
        flex: 0 0 auto;
        width: 19.2% !important;
    }
    
    .fifty-percent {
        flex: 0 0 auto;
        width: 19.2% !important;
    }
    
    .seventy-percent {
        flex: 0 0 auto;
        width: 19.2% !important;
    }
    
    .hundred-percent {
        flex: 0 0 auto;
        width: 27.6% !important;
    }

    .vertical-box-4 {
        flex: 0 0 auto;
        width: 28% !important;
        margin-left: 0rem !important;
        padding: 0px !important;
    }
    
    .vertical-box > span {
        border-radius: 4px;
        /* color: white; */
        padding: 4px 8px;
        font-size: 10px;
        vertical-align: top;
    }

    .vertical-box {
        text-align: end;
        margin-left: 17.5px;
    }

    .vertical-box-3 {
        margin-left: 0px;
    }

    .progress-set {
        padding: 0rem 1rem 0rem 1rem;
    }
}

@media only screen and (max-width: 500px) {
    .claim-list {
        flex-wrap: wrap;
    }

    .claim-list > div {
        padding-bottom: 15px;
    }

    .claim-info {
        padding: 1rem 1rem;
    }

    .claim-instruction {
        font-size: 13px;
        padding: 2rem 1rem 0.5rem;
    }

    .claim-instruction > p:nth-child(2) {
        color: #8A8A9E;
        font-size: 10px;
    }

    .point-container {
        padding: 2rem 0rem;
    }

    .progress-container {
        padding: 1rem 1rem 1rem;
    }

    .progress-set {
        padding: 0rem;;
    }

    .progress-indicator-sett {
        padding-left: 0rem;
    }

    .ten-percent {
        flex: 0 0 auto;
        width: 10% !important;
    }
    
    .thirty-percent {
        flex: 0 0 auto;
        width: 20% !important;
    }
    
    .fifty-percent {
        flex: 0 0 auto;
        width: 20% !important;
    }
    
    .seventy-percent {
        flex: 0 0 auto;
        width: 20% !important;
    }
    
    .hundred-percent {
        flex: 0 0 auto;
        width: 29% !important;
    }

    .vertical-line {
        /* border-right: 2px solid #1178FF; */
        content: '';
        /* width: 100%; */
        height: 25px;
    }

    .vertical-box-4 {
        flex: 0 0 auto;
        width: 25% !important;
        margin-left: 0rem !important;
        padding: 0px !important;
    }
    
    .vertical-box > span {
        border-radius: 4px;
        /* color: white; */
        padding: 2px 6px;
        font-size: 9px;
        vertical-align: top;
    }

    .vertical-box {
        text-align: end;
        margin-left: 13.5px;
    }

    .vertical-box-3 {
        margin-left: 0px;
    }

    .claim-airtime > button {
        background: #1178FF;
        border-radius: 8px;
        color: white;
        font-weight: 700;
        text-transform: capitalize;
        display: block;
        border: 0px;
        margin: auto;
        font-size: 11px;
        padding: 0.75rem 1rem;
    }

    .claim-option-tab {
        padding: 2rem 1rem;
    }
}

@media only screen and (max-width: 280px) {
    .vertical-box {
        text-align: end;
        margin-left: 7px;
    }

    .progress {
        height: 0.5rem !important;
    }

    .vertical-box > span {
        font-size: 7px;
        padding: 2px 4px;
    }

    .vertical-box-3 {
        margin-left: 1px;
    }

    .vertical-box-4 {
        flex: 0 0 auto;
        width: 24% !important;
    } 
    
    .progress-container > div {
        padding: 1rem 0rem;
    }

    .claim-airtime > button {
        padding: 0.5rem 1rem;
    }

    .claim-instruction {
        font-size: 10px;
        padding: 2rem 1rem 0.5rem;
    }

}