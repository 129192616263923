/* .question-instruction {

} */

.corection-table {
    border: 1px solid #003DC9;
    border-radius: 6px;
}

.corection-table > div {
    display: grid;
    grid-template-columns: 50% auto;
    text-align: center;
    border-bottom: 1px solid #003DC9;
}

.corection-table > div > p {
    border-right: 1px solid #003DC9;
    margin-bottom: 0px;
    padding: 0.5rem 0rem;
}

.snd-table {
    border-bottom: 0px !important;
}

.correct-ans {
    color: #00BB29;
}

.wrong-ans {
    color: red;
}

.second-snd-table {
    border-right: 0px !important;
}

.correction-container {
    padding: 0rem 0rem 2rem;
}

.explanation-button {
    border: 0px;
    border-radius: 8px;
    color: white;
    font-weight: 700;
    background-color: #003DC9;
    padding: 10px 15px;
}

.line-1{
    border-right: .15em solid #003DC9;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end), blink .5s step-end infinite alternate;;
    animation: type2 2s steps(40, end), blink .5s step-end infinite alternate;;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; 
}

.line-2 {
    margin-top: 1rem;
  }

@keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    
    100% {
      opacity: 1;
      border: none;
    }
  }

  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }

  

  .more-container {
    display: flex;
    gap: 1rem;
    margin: 1rem 0rem;
  }

  .more-container > button {
    border: 0px;
    background-color: #003DC9;
    color: white;
    border-radius: 4px;
  }

  .speaker {
    background-color: #003DC9;
    border-radius: 4px;
    padding: 5px;
  }

  .speaker > svg {
    width: 22px;
    fill: white;
  }

  .currently-speaking {
    background-color: gray;
  }

  .currently-speaking > svg {
    fill: white;
  }

.correction-section {
    margin: 2rem 0rem 0rem;
}

.pag-button {
    border: 0rem;
    border-radius: 8px;
    color: white;
    font-weight: 600;
    background-color: #003DC9;
    padding: 10px 15px;
}

.pag-button-container {
    display: flex;
    justify-content: space-between;
}

.question-number {
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 0.6rem;
}

.correction-box {
  display: flex;
  border-radius: 12px;
  background-color: #FFF27A;
  color: black;
  margin: 3rem 0rem 0rem;
}

.correction-box > div {
  display: flex;
  border-radius: 12px;
  border-width: 0px 1.5px 0px 0px;
  border-style: solid;
  border-color: black;
  padding: 1rem 2rem;
  width: 35%;
}

.correction-box > div:nth-child(2) {
  width: 20%;
}

/* .explain-answer {
  background-color: black;
  color: white;
  border-radius: 8px;
} */

.correction-box > div:nth-child(3) {
  border: 0rem;
  width: 45%;
}

.correction-box > div:nth-child(3) > div > p:nth-child(3) {
  margin-bottom: 0.5rem;
  font-size: 13px;
}

.answer-container {
    /* background-color: rgba(109, 172, 254, 0.04); */
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    background-color: rgba(50, 139, 255, 0.04);
    border: 1px solid rgba(0, 7, 15, 0.51);
    border-radius: 8px 8px 0px 0px;
}

.explain-container {
  padding: 1rem 1.5rem 0.5rem 1.5rem;
  background-color: #FFF27A;
  color: black;
  border-radius: 8px;
  margin-top: -10px;
}

.post-question > span {
  background-color: #FFF27A;
  border-radius: 12rem;
  width: 34px;
  height: 34px;
  text-align: center;
    align-content: center;
    display: grid;
    justify-content: center;
}

.post-question > span > svg {
  width: 18px;
  height: 19px;
}

.post-question > span > svg > path {
  fill: black;
}

.answer, .correct-answer, .explain-answer {
    margin-bottom: 1rem;
}

.answer-title, .correct-answer-title, .explain-answer-title {
    display: flex;
    gap: 10px;
    width: fit-content;
}

.correct-dot-ans {
    background-color: #00BB29 !important;
    border: 2px solid #00BB29 !important;
}

.wrong-dot-ans {
    background-color: #FE2121;
}

.answer-title > span {
    content: '';
    width: 8px;
    height: 8px;
    background-color: #FE2121;
    border: 2px solid #FE2121;
    border-radius: 50%;
    margin: auto 0px;
}

.answer-title > p {
    margin: auto 0px;
    font-weight: 300;
    font-size: 14px;
}

.no-dot > span {
    visibility: hidden;
}

.no-dot > p, .no-dot1 > p, .no-dot1 > .Typewriter > .Typewriter__wrapper {
    font-weight: 600;
    font-size: 14px;
}

.correct-answer-title > span, .explain-answer-title > span {
    content: '';
    width: 8px;
    height: 8px;
    background-color: #00BB29;
    border: 2px solid #00BB29;
    border-radius: 50%;
    margin: auto 0px;
}

.correct-answer-title > p, .explain-answer-title > p {
    margin: auto 0px;
    font-weight: 300;
    font-size: 14px;
}

.no-dot > span, .no-dot1 > span {
    visibility: hidden;
}

.answer-container > p > p > span, .answer-container > p > span {
    font-family: var(--fontfamily) !important;
}

.typewritercursor {
    display: none;
}

@media only screen and (max-width: 1280px) {
  .correction-box > div {
    padding: 1rem 1rem;
  }
}

@media only screen and (max-width: 1024px) {
  .question-progress-info > p:nth-child(1) {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 0.5rem;
}

.question-progress-info > p:nth-child(2) {
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 0.5rem;
}

.correction-box > div:nth-child(3) > div > p:nth-child(3) {
  margin-bottom: 0.5rem;
  font-size: 10px;
}

.correction-box > div > span > svg {
  width: 17px;
  height: 18px;
}

.post-question > span > svg {
  width: 15px;
  height: 16px;
}
}

@media only screen and (max-width: 992px) {

}

@media only screen and (max-width: 768px) {
  .question-progress-info {
    padding-left: 10px;
}
}

@media only screen and (max-width: 600px) {
  .correction-box {
    display: block;
    margin: 1rem 0rem 0rem;
  }

  .correction-box > div {
    width: 100%;
  }
  .correction-box > div:nth-child(2) {
    width: 100%;
}
.correction-box > div:nth-child(3) {
  width: 100%;
}

.post-question {
  gap: 1.5rem;
}

.post-question > p {
  font-size: 14px;
}
}

@media only screen and (max-width: 500px) {
    .answer-container {
        background-color: rgba(109, 172, 254, 0.04);
        padding: 1rem;
    }

    .post-question > span {
      width: 34px;
      height: 27px;
    }
}