/* .body {
    margin: 0px 6rem 0px 3.5rem !important;
} */

/* .img-container > img {
    max-width: 95%;
} */

.home-container {
    margin: 3px 6rem 0px 3.5rem !important;
}

.home-container > div {
        margin-top: 3px !important;
}

.home-title > h1 {
    font-size: 108px;
    font-weight: 600;
    padding-left: 30px;
    font-family: var(--secondaryfontfamily);
    color: var(--secondarycolor);
    line-height: 130%;
}

.home-title > img {
    padding-left: 30px;
}

.home-title > div > span {
    border: 1px solid #676E82;
    padding: 8px 20px;
    border-radius: 16px;
    text-transform: uppercase;
    text-align: center;
    color: #676E82;
    cursor: pointer;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    font-size: 14px;
    height: 60px;
}

.excel-grid {
    grid-gap: 10px;
    grid-template-columns: 100px 105px 105px;
    padding-left: 30px;
    gap: 0px 10px;
}

.img-container > img {
    max-width: 100%;
}

.cea {
    grid-column: 1 / span 2;
}

.home-par {
    margin-top: 30px;
    padding: 0px 30px;
    font-size: 18px;
    font-weight: 400;
}

.home-list {
    background-color: var(--secondarycolor);
    margin-top: 63px !important;
}

.home-list > div {
    margin: 3rem 0rem;
    background-color: white;
    display: flex;
    justify-content: center;
    padding: 20px 0px;
}

.pq-box {
    border-right: 3px solid var(--secondarycolor);
    padding: 15px 18px;
}

.pq-box > div > div > span {
    font-size: 20px;
    font-weight: 600;
    vertical-align: middle;
    font-family: 'Clash Display Variable';
    /* margin-left: 10px; */
    color: var(--secondarycolor);
}

.pq-box > div > div > p {
    font-size: 11px;
    margin-bottom: 0px;
}

.pq-box > div {
    display: flex;
}

.pq-box > div > div {
    padding-left: 10px;
}

.weib {
    margin: 5rem 0rem !important;
}

.weib > h2 {
    text-align: center;
    font-size: 32px;
    font-family: var(--secondaryfontfamily);
}

.aotp {
    background-color: var(--secondarycolor);
}

.aotp > div {
    margin: 3rem 0px;
}

.aotp > div > h2 {
    text-align: center;
    font-size: 32px;
    font-family: var(--secondaryfontfamily);
    color: white;
}

.aotp > div > div {
    display: flex;
    justify-content: center;
    background-color: #1D3797;
    border-radius: 24px;
    padding: 15px 0px;
}

.wb-box {
    display: flex;
    border-right: 3px solid #FFFFFF;
    padding: 15px 25px;

}

.wb-box > div {
    padding-left: 15px;
}

.wb-box > div > a {
    display: block;
    font-size: 11px;
    color: white;
}

.wb-box > div > span {
    font-family: var(--secondaryfontfamily);
    font-size: 28px;
    color: white;
}

.mps {
    margin-top: 113px !important;
}

.mps > div > div > div > h2 {
    font-size: 32px;
    font-family: var(--secondaryfontfamily);
}

.mps-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 30px;
    text-align: initial;
    margin-top: 3.75rem;
}

.mps-grid > div {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(11, 34, 57, 0.14);
    border-radius: 4px;
    padding: 15px 20px 15px 15px;
}

.mps-grid > div > h3 {
    font-size: 16px;
    font-weight: 700;
    font-family: var(--fontfamily);
}

.mps-grid > div > p {
    font-size: 14px;
}

.mps-grid > div > div > a {
    font-size: 16px;
    text-decoration: none;
    color: #0172DA;
}

.arrow {
    float: right;
}

.uteptr {
    margin-top: 6rem !important;
}

.uteptr > div > h3 {
    font-size: 32px;
    font-family: var(--secondaryfontfamily);
    font-weight: 600;
    color: #0B2239;
}

.playbutton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #F8ECD7;
    background-color: transparent;
    padding: 15px 30px;
    border-radius: 5px;
    cursor: pointer !important;
    z-index: 99;
}

.playbutton > span {
    color: #F8ECD7;
    padding-left: 10px;
}

.vid {
    cursor: pointer;
}

.uteptr > div > div {
    position: relative;
    margin-top: 3rem;
}

/* .weib > div {
    margin-top: 1.5rem;
} */

/* .weib {

} */

.weib-img-mobile  {
    display: none;
}

.weib-info > span {
    text-align: center;
    border-bottom: 1px solid #5C5C5C;
    width: 60px;
    margin: 0px auto;
    display: inline-block;
}

.weib-info {
    padding: 7rem 0rem;
}

.weib-info > h3 {
    font-weight: 700;
}

.weib-img > img {
    max-width: 100%;
}

.home-sm-title {
    display: flex;
}

.ctalog {
    border: 1px solid #002099;
border-radius: 4px;
color: #002099;
font-weight: 700;
font-size: 16px;
text-decoration: none;
padding: 10px 20px;
margin-left: 15px;
}

.ctareg {
    border: 1px solid #1178FF;
    background-color: #002099;
    border-radius: 4px;
    color: white;
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    padding: 10px 30px;
}

.ctareg:hover {
    color: white;
}

.cta {
    padding: 10px 30px;
}
.weib-img-mobile > img  {
    width: 100%;
}





@media only screen and (max-width: 1280px) {
    .img-container > img {
        max-width: 100%;
    }

    .home-container {
        margin: 0px 3rem 0px 1.5rem !important;
    }

    .home-title > h1 {
        font-size: 95px;
        line-height: 1.2;
    }

    .home-title > div > span {
        border: 1px solid #676E82;
        padding: 5px 15px;
    }

    .home-par {
        margin-top: 40px;
    }

    .home-title > div > span {
        height: 50px;
    }

    .weib-info {
        padding: 5rem 0rem;
    }
}

@media only screen and (max-width: 1024px) {
    .home-container {
        margin: 0px 2rem 0px 0.5rem !important;
    }
    
    .home-title > img {
        padding-left: 30px;
        height: 100px;
    }

    .home-title > h1 {
        font-size: 75px;
    }

    .excel-grid {
        grid-gap: 10px;
        grid-template-columns: 90px 91px 87px;
        padding-left: 22px;
    }

    .home-title > div > span {
        height: 45px;
    }

    .home-par {
        margin-top: 25px;
        font-size: 15px;
    }

    .pq-box > div > div > span {
        font-size: 14px;
    }

    .home-list > div {
        padding: 15px 0px;
    }

    .pq-box {
        padding: 10px 18px;
    }

    .wb-box > img {
        max-width: 35%;
    }

    .wb-box > div > span {
        font-family: var(--secondaryfontfamily);
        font-size: 19px;
    }

    .wb-box {
        padding: 15px 15px;
    }
}

@media only screen and (max-width: 992px) {
    .home-container {
        margin: 0px;
    }

    .home-title{
        justify-content: space-evenly;
    }

    .home-title > h1 {
        font-size: 103px;
        padding-left: 0px;
    }

    .home-title > img  {
        height: auto;
        padding-left: 0px;
    }

    .home-par {
        padding: 0px 70px;
    }

    .img-container {
        display: none;
    }

    .vid {
        width: -webkit-fill-available;
    }

    .home-list > div {
        padding: 15px 0px;
    }

    .pq-box {
        padding: 8px 7px;
    }

    .home-title > div > span {
        height: 50px;
        line-height: 1.2;
    }

    .excel-grid {
        gap: 0px 10px;
    }

    .cta {
        text-align: center;
    }

    .wb-box > img {
        max-width: 20%;
    }

    .wb-box > div > span {
        font-size: 16px;
    }
}

@media only screen and (max-width: 768px) {
    .wb-box {
        border-right: 0px;
        border-bottom: 3px solid #FFFFFF;
        margin: 15px 30px;
        padding: 15px 20px;
    }

    .aotp > div > div {
        display: block;
    }

}

@media only screen and (max-width: 1200px) {
    .wb-box {
        padding: 15px 25px;
    }

}

@media only screen and (max-width: 1145px){
.wb-box {
    padding: 15px 20px;
}
}

@media only screen and (max-width: 1100px){
    .wb-box {
        padding: 15px 15px;
    }
}

@media only screen and (max-width: 1065px){
    .wb-box {
        padding: 15px 12px;
    }
}


@media only screen and (max-width: 600px) {
    .vid {
        width: -webkit-fill-available;;
    }

    .home-title > img {
        height: 100px;
    }

    .home-title > h1 {
        font-size: 75px;
    }

    .home-title > div > span {
        padding: 0px;
    }

    .excel-grid {
        padding-left: 0px;
        grid-gap: 5px;
        grid-template-columns: 75px 75px 75px;
    }
    
    .home-par {
        padding: 0px;
    }

    .footer {
        padding: 3.5rem 0.5rem 1.5rem 0.5rem;
    }

    .footer > div {
        text-align: center;
    }

    .footer-center {
        margin-top: 20px;
    }

    .home-list > div {
        display: block;
    }

    .pq-box {
        border-right: 0px;
        border-bottom: 3px solid var(--secondarycolor);
        margin: 0px 18px;
        padding: 15px 18px;
    }

    .weib-img-mobile  {
        display: block;
    }

    .weib-img-mobile > img  {
        width: 100%;
    }

    .weib-img-mobile-lg  {
        display: none;
    }

    .aotp > div > div {
        display: block;
    }
    
    .wb-box {
        border-right: 0px;
        border-bottom: 3px solid #FFFFFF;
        margin: 15px 30px;
        padding: 15px 20px;
    }


    .home-container {
        margin: 25px 0px 0px !important;
    }

}

@media only screen and (max-width: 500px) {
    .home-title > img {
        height: 60px;
    }

    .home-title > h1 {
        font-size: 50px;
    }

    .home-container {
        margin: 0px !important;
    }

    .home-sm-title {
        display: block !important;
    }

    .home-sm-title > h1 {
        font-size: 95px;
        text-align: center;
    }

    .home-par {
        padding: 0px 25px;
    }

    .excel-grid {
        padding-left: 0px;
        grid-gap: 10px;
        grid-template-columns: 85px 85px 85px;
        justify-content: center;
    }

    .home-list {
        display: block;
    }

    .home-list > div {
        display: block;
    }

    .pq-box {
        border-right: 0px;
        border-bottom: 3px solid var(--secondarycolor);
        margin: 0px 18px;
        padding: 15px 18px;
    }

    .aotp > div > div {
        display: block;
    }
    
    .wb-box {
        border-right: 0px;
        border-bottom: 3px solid #FFFFFF;
        margin: 15px 30px;
        padding: 15px 20px;
    }

    .playbutton {
        padding: 10px 5px;
    }

    .weib-img-mobile  {
        display: block;
    }

    .weib-img-mobile > img  {
        width: 100%;
    }

    .weib-img-mobile-lg  {
        display: none;
    }

    .footer {
        padding: 2.5rem 0.5rem 1.5rem 0.5rem;
        text-align: center;
    }

    .footer > div > div {
        margin-top: 20px;
    }

    .footer-center {
        margin-top: 0px;
    }

    .footer-end {
        padding: 1.2rem 2rem;
        font-size: 12px;
    }

    .home-container {
        margin-top: 25px !important;
    }

    .home-container > div {
        margin-top: 3px !important;
    }

    .cta {
        padding: 3px 1px;
    }

    .ctalog {
        padding: 10px 10px;
    }

    .ctareg {
        padding: 10px 15px;
    }
    
}