.header{
    padding: 0rem 4rem 0rem 2rem;
    height: 6rem;
}

.header-right {
    padding: 0.75rem 0rem 0rem;
    margin: 0rem;
}

.header-right > p {
    font-size: 14px;
    margin-bottom: 0px;
}

.header-right > p > a {
    text-decoration: none;
    color: var(--primarycolor);
}

.register-social-button {
    background-color: white;
    border: 0px;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px;
    margin: 0.75rem 0rem;
    padding: 0.5rem 0rem;
    height: 50px;
}

.social-list-button {
    width: 50%;
    /* text-align: center; */
    margin: 4rem auto !important;
}

/*.social-list-button > div:nth-child(2) {*/
/*    background: rgb(59, 89, 152);*/
/*    color: white;*/
/*}*/



.register-social-button > button {
    border: 0px;
    background-color: white;
    padding: 0px;
}

.mainbody {
    padding-left: 58px;
}

.title {
    font-size: 40px;
    font-weight: 800;
    line-height: 135%;
    text-transform: capitalize;
    font-family: var(--fontfamily);
}

label {
    display: block !important;
    padding-bottom: 5px;
    color: #00070F;
    font-size: 16px;
}

input, .countryselect {
    width: 100%;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    height: 35px;
    padding-left: 10px;
}

input:focus-visible {
    outline-color: var(--primarycolor);
}

.checkbox > input {
    width: 12px;
    height: 12px;
    vertical-align: middle;
}

.checkbox > span {
    font-size: 10px;
    font-family: var(--fontfamily);
    vertical-align: middle;
    padding-left: 5px;
}

.checkbox > span > a{
    color: var(--primarycolor);
    text-decoration: none;
}

.nxt-button {
    padding: 0.65rem;
    cursor: pointer;
    text-align: center;
}

.reg-instruction {
    font-size: 12px;
    margin: 0px;
    padding-top: 3.5rem;
}

.regload {
    align-self: center;
    padding-left: 1rem;
}

.register-button {
    width: 100%;
    border: 1px solid var(--primarycolor);
    background-color: var(--primarycolor);
    color: white;
    font-size: 16px;
    font-weight: 800;
    height: 50px;
    border-radius: 4px;
    margin-top: 33px;
}

.footericonleft > img {
    float: right;
}

.text {
    font-size: 12px;
}

.text-md {
    font-size: 16px;
}

.label {
    font-size: 14px;
}

.resetcode-button {
    margin-top: 63px;
}

.resetpassword-button {
    margin-top: 63px;
}

.rest-p {
    margin-top: 3rem ;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.input-half  {
    padding-left: 0px !important;
}

.input-half-2 {
    padding-right: 0px !important ;
}

.errormessage {
    font-size: 12px;
    color: red;
    padding-top: 0.5rem;
    margin-bottom: 0rem;
    font-style: italic;
}

.PhoneInputCountryIcon {
    margin-right: 0.5rem;
}

.passwordinput {
    position: relative;
    display: flex;
    width: 100%;
}

.passwordinput > input {
    border-right: 0px;
    border-radius: 4px 0px 0px 4px;
    padding-left: 10px;
}

.passwordinput > span {
    border: 1px solid #E0E0E0;
    border-left: 0px;
    border-radius: 0px 4px 4px 0px;
    padding: 3px 5px;
    cursor: pointer;
}

input:focus-visible, .passwordinput > input:focus-visible {
    outline: 0px;
}

.resendemail {
    color: var(--primarycolor);
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
}

@media only screen and (max-width: 1280px) {
    .title {
        font-size: 38px;
    }

    .input-half  {
        padding-left: 0px !important;
    }

    .input-half-2 {
        padding-right: 0px !important ;
    }

    .rest-p {
        margin-top: 0px ;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

}

@media only screen and (max-width: 1024px) {
    .header{
        height: 4rem;
        /* margin-top: -15px !important; */
    }

    .header > div > svg {
        width: 100%;
    }

    .header-right {
        padding: 0.75rem 0rem;
    }

    .header-right > p {
        font-size: 12px;
    }

    .title {
        font-size: 35px;
        font-weight: 800;
        line-height: 110%;
    }

    .mainbody {
        padding-left: 50px;
    }

    .email-confirm > div {
        padding-bottom: 0px !important;
    }

    label {
        font-size: 14px;
    }

    .input-half  {
        padding-left: 0px !important;
    }

    .input-half-2 {
        padding-right: 0px !important ;
    }

    .register-title {
        font-size: 29px;
    }

    .mainbody > div > div {
        margin-top: 15px;
    }

    .mainbody-resetcode {
        padding-left: 15px;
    }

    .mainbody-resetcode > div > h1{
        font-size: 34px;
    }

    .email-con-img > img {
        max-width: 100%;
    }

    .header-con {
        padding: 0px;
    }

}

@media only screen and (max-width: 992px) {
    .header-right {
        padding: 0.8rem 0rem;
    }

    .header {
        padding: 0rem 0rem 0rem 0rem;
    }

    .mainbody {
        padding-left: 20px;
        padding-top: 20px;
        padding-right: 20px;
    }

    .input-half  {
        padding: 0px !important;
    }

    .input-half-2 {
        padding: 0px !important ;
    }

    .header > div > svg {
        width: 100%;
        /* padding: 1.5rem 0rem; */
    }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 600px) {

    .header-right {
        text-align: right;
    }

    .form-box {
        margin-top: 25px;
    }
    
    .mainbody {
        padding-left: 20px;
        padding-top: 0px;
        padding-right: 20px;
    }

    .mainbody > div > div {
        margin-top: 15px !important;
    }

    .reg-instruction {
        font-size: 12px;
        margin: 0px;
        padding-top: 1.5rem;
    }
}

@media only screen and (max-width: 500px) {
    .header-right {
        padding: 0.9rem 0rem;
    }

    .header-right > p {
        font-size: 9px;
    }

    .mainbody {
        padding-left: 0px;
        padding-right: 0px;
    }

    .header > div {
        margin: 0px;
    }

    .social-list-button {
        margin: 4rem auto!important;
        width: 70%;
    }

    .header {
        height: 3rem;
    }

    .form-box {
        margin-top: 50px !important;
    }

    .footericonleft > img, .footericonright > img {
        max-width: 100%;
    }

    .resetpassword-button {
        margin-top: 40px;
    }

    .resetcode-button {
        margin-top: 35px;
    }

    .title {
        font-size: 30px;
        font-weight: 800;
        line-height: 110%;
        padding-top: 0.5rem;
    }

    .registerfooter {
        display: none;
    }

    .reg-instruction {
        font-size: 12px;
        margin: 0px;
        padding-top: 1.5rem;
    }
}

@media only screen and (max-width: 500px) {
    .social-list-button {
        margin: 4rem auto!important;
        width: 90%;
    }
}
