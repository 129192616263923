.leaderboard-container {
    background: #FFFFFF;
box-shadow: 0px 4px 17px rgba(11, 34, 57, 0.1);
border-radius: 11px;
width: 750px;
height: 600px;
margin: 3rem auto;
padding: 3.5rem 1.5rem;
}

.lead {
    overflow: auto;
    height: 500px;
}

.lead::-webkit-scrollbar {
    width: 10px;
    right: 1.5rem;
}

.lead::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 32, 153, 0.1); 
    border-radius: 10px;
}

.lead::-webkit-scrollbar-thumb {
    background-color: #002099; 
    border-radius: 10px;
}

.leaderboard-list {
    display: flex;
    /* justify-content: space-evenly; */
    gap: 6rem;
    margin: 0rem 0rem 2rem;
}

.ranking {
    border-radius: 50%;
    background-color: #002099;
    width: 35px;
    height: 35px;
    color: white;
    padding: 3px 11px;
    margin: auto;
    font-weight: 700;
    font-size: 16px;
}

.rank1:first-child {
    border: 2px solid #FAB912;
}

.rank2:nth-child(1){
    border: 2px solid #A8AEB2;
}
.rank3:nth-child(1){
    border: 2px solid #D86422;
}

.leaderboard-list > div > div > h5, .leaderboard-list > div > h5 {
    font-size: 18px;
    font-weight: 600;
}

.leaderboard-list > div > div > p, .leaderboard-list > div > p {
    font-size: 12px;
    color: rgba(0, 7, 15, 0.76);
    font-weight: 300;
}

@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 1024px) {
    .leaderboard-container {
        width: 675px;
    }
}

@media only screen and (max-width: 992px) {
    
}

@media only screen and (max-width: 768px) {
    .leaderboard-container {
        width: 600px;
    }
}

@media only screen and (max-width: 600px) {
    .leaderboard-container {
        width: 465px;
        margin: 1rem auto;
        height: 545px;
        padding: 1.5rem 1.5rem;
    }
    .leaderboard-list {
        gap: 2rem;
    }
    .leaderboard-list > div > div > h5, .leaderboard-list > div > h5 {
        font-size: 16px;
        font-weight: 600;
    }
    .ranking {
        width: 25px;
        height: 25px;
        padding: 1px 8px;
        font-size: 14px;
    }

}

@media only screen and (max-width: 500px) {
    .leaderboard-list > div > div > h5, .leaderboard-list > div > h5 {
        font-size: 12px;
        font-weight: 600;
    }
    .leaderboard-list {
        gap: 1rem;
    }
    .leaderboard-list > div > div > p, .leaderboard-list > div > p {
        font-size: 8px;
        color: rgba(0, 7, 15, 0.76);
        font-weight: 300;
    }
    .leaderboard-container {
        width: 330px;
        padding: 1.5rem 0.75rem;
        margin: 2.5rem auto;
    }    
}