.blog-container {
    padding: 1rem 6.2rem 0rem 5rem;
}

.gtlu {
    background-color: var(--secondarycolor);
    color: white;
}

.gtlu > div {
    padding: 6rem 4rem 6rem;
}

.gtlu > div > h3 {
    font-family: var(--secondaryfontfamily);
    font-size: 40px;
    padding: 10px 0px;
}

.blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    grid-gap: 70px;
    padding: 7rem 20px 0rem 20px;
}

.blog-grid > div > div > img {
    max-width: 100%;
}

.blog-grid > div > h5 {
    font-size: 25px;
    text-transform: capitalize;
    font-weight: 700;
    color: #1C0A00;
    padding-top: 25px;
}

.blog-grid > div > p {
    font-size: 18px;
    color: rgba(28, 10, 0, 0.78);
    padding-top: 10px;
}

.blog-button {
    display: flex;
    padding: 10px 25px 10px 15px;
    background-color: #1178FF;
    width: fit-content;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}

.blog-button > p {
    font-size: 15px;
    margin-bottom: 0px;
    color: white;
}

.blog-button > img {
    padding-left: 20px;
}

@media only screen and (max-width: 1280px) {
    .blog-container {
        padding: 1rem 2rem 0rem 2rem;
    }
    
}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 992px) {
    .blog-container {
        padding: 1rem 1rem 0rem 1rem;
    }

    .blog-grid {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 40px 20px;
    }
}

@media only screen and (max-width: 768px) {
    .blog-grid > div > div {
        text-align: center;
    }

    .blog-grid > div > h5 {
        padding: 25px 15px 0px 15px;
    }

    .blog-grid > div > p {
        padding: 25px 15px 0px 15px;
    }

    .blog-button {
        margin: 0px 15px;
    }
}

@media only screen and (max-width: 600px) {
    .blog-container{
        padding: 0px;
    }
  .blog-grid > div > h5 {
        padding: 20px 0px 0px;
    }

    .blog-grid > div > p {
        padding: 20px 0px 0px;
    }

    .blog-button {
        margin: 0px 0px;
    }

    .gtlu > div {
        padding: 4rem 2rem 4rem;
    }
}

@media only screen and (max-width: 500px) {
    .page-link {
    padding: 0.35rem 0.3rem !important;
    }
}
