.supportcontent {
    padding-top: 4rem;
}

.support-form > div > div {
    padding-left: 0px;
}

.support-form > div > div > input {
    border-bottom: 1px solid #DDDDDD !important;
    border: 0px;
    border-radius: 0px;
}

.support-form > div > div > input:focus-visible {
    border-bottom: 1px solid #1178FF !important;
    border: 0px;
    border-radius: 0px;
    outline: none;
}

.support-form > div > input {
    border-bottom: 1px solid #DDDDDD !important;
    border: 0px;
    border-radius: 0px;
}

.support-form > div > input:focus-visible {
    border-bottom: 1px solid #1178FF !important;
    border: inherit;
    border-radius: 0px;
    outline: none;
}

.support-form > div > textarea {
    border-bottom: 1px solid #DDDDDD !important;
    border: 0px;
    border-radius: 0px;
    width: 100%;
}

.support-form > div > textarea:focus-visible {
    border-bottom: 1px solid #1178FF !important;
    border: 0px;
    border-radius: 0px;
    width: 100%;
    outline: none;
}

textarea::placeholder, input::placeholder{
    color: #DDDDDD;
    vertical-align: bottom;
}

.support-form > div > div > label {
    font-weight: 600;
}

.support-form > div > label {
    font-weight: 600;
}

.support-form > button {
    background-color: var(--primarycolor);
    color: white;
    align-items: center;
    vertical-align: middle;
    font-size: 14px;
    border: 0px;
    padding: 10px;
    font-weight: 600;
    margin-top: 2rem;
}

.support-form > button > img {
    padding-left: 10px;
}

.support-card {
    border: 1px solid #C1C1C1;
border-radius: 12px;
padding: 30px !important;
margin-top: 3.5rem !important;
}

.support-card > div > span {
    font-weight: 600;
font-size: 14px;
}

.support-card > div > p {
    font-weight: 700;
font-size: 20px;
}

.support-social {
    margin-top: 3rem;
    font-weight: 600 !important;
font-size: 14px !important;
margin-bottom: 0px;
}

.support-card > div > div > a {
    padding: 3px;
}

.ticket-modal > h4 {
    font-weight: 600;
    font-size: 1.25rem;
    color: #00A82F;
    margin-bottom: 0.85rem;
}

.ticket-modal > p {
    font-size: 14px;
}

.ticket-close > button {
    font-size: 12px;
}
