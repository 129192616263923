.profilesettingbox {
    box-shadow: 0px 4px 22px rgba(0, 7, 15, 0.12);
border-radius: 12px;
margin: 3rem 7rem 0rem;
}

.psboxtitle {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #DDDDDD;
}

.psinput-mobile {
    width: 50%;
}

.interest-info {
    display: flex;
    margin-top: 1.5rem;
}

.h13 {
    text-align: center;
    font-family: sans-serif;
    text-transform: capitalize;
    font-size: 16px !important;
    padding: 0.15rem 4rem;
    font-weight: 700 !important;
    position: relative;
    margin: 0rem;
}

.h13:after {
    position: absolute;
    content: "";
    -webkit-animation: Dots 2s cubic-bezier(0, .39, 1, .68) infinite;
    animation: Dots 2s cubic-bezier(0, .39, 1, .68) infinite;
}

.interest-info > span {
    content: '';
    height: 10px;
    width: 10px;
    background-color: #1178FF;
    border-radius: 50%;
    margin: auto 0px;
}

.interest-info > p {
    font-size: 12px;
    margin-bottom: 0px;
    padding-left: 15px;
}

.interest-form {
    width: 300px;
    margin: 2.5rem auto 0rem;
}

.interest-form > div {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 0rem;
}

.interest-form > div > input[type= 'checkbox' i] {
    width: auto;
    height: auto;
    border: 1px solid #1178FF;
border-radius: 4px;
}

.interest-form > div > label {
    padding-bottom: 0px;
    /* padding-left: 15px; */
    font-weight: 700;
}

.interest-form > button {
    border: 0px;
    background-color: #1178FF;
    color: white; 
    padding: 11px 15px; 
    font-weight: 700;
    border-radius: 8px;
    margin: 1.5rem 0rem;  
}

.psboxtitle > p {
    padding: 15px;
    margin-bottom: 0px;
    cursor: pointer;
    margin: 0px 25px;
    font-weight: 600;
    font-size: 18px;
}

.psboxtitle > p > span {
    vertical-align: middle;
    padding-left: 10px;
}

.profile-edit-submit {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.profile-edit-submit > p {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
    margin: auto 0rem;
}

.profile-edit-submit > button {
    border: 0px;
    background-color: #1178FF;
    border-radius: 8px;
    color: white;
    width: 130px;
    height: 40px;
}

.activetitle {
    border-bottom: 4px solid #002099;;
    border-radius: 3px;
}

.nonetab {
    display: none !important;
}

.pfp {
    width: 70px;
    height: 70px;
}

.imagebox > div > span {
    font-weight: 600;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    padding-bottom: 10px;
}

.imagebox {
    text-align: left;
    display: flex;
}

.imagebox > div > span:hover {
    color: var(--secondarycolor);
}

.imagebox > div {
    margin: auto 0.75rem;
}

.imagebox > div > p {
    font-weight: 400;
    font-size: 10px;
    color: rgba(0, 7, 15, 0.65);
    margin-bottom: 0px;
}

.activetab {
    padding: 15px 6rem;
    text-align: center;
}

.psinput {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: 60px;
    text-align: left;
}

.psinput1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
    gap: 20px;
}

.psinput > div > label, .psinput1 > div > label {
    font-weight: 700;
    font-size: 14px;
}

.psinput > div {
    padding-top: 1rem;
}

.activetab > div > form > button {
    border-radius: 4px;
    background-color: var(--primarycolor);
    border: 0px;
    font-weight: 700;
    font-size: 16px;
    color: white;
    margin-top: 2.5rem;
    width: 130px;
    height: 40px;
}

/* .psinput > div > input {

} */

.pspassword {
    margin: auto;
    width: 45%;
    text-align: left;
}

.pspassword > label {
    margin-top: 1.5rem;
    font-weight: 500;
font-size: 14px;
}

.pspassword > button {
    font-weight: 700;
    font-size: 16px;
    border-radius: 4px;
    background-color: var(--primarycolor);
    border: 0px;
    color: white;
    padding: 10px 0px;
    margin-top: 2.5rem;
    width: 100%;
}

.pspassword > p {
    text-decoration: underline;
    font-weight: 300;
    font-size: 16px;
    color: var(--primarycolor);
    text-align: center;
    cursor: pointer;
    margin-top: 1.2rem;
}

.psemaireset {
    margin: auto;
    width: 45%;
    text-align: left;
    padding: 30px 0px 5rem;
}

.psemaireset > p {
    font-weight: 300;
    font-size: 14px;
    color: #746D69;;
    text-align: left;
    cursor: pointer;
    margin-top: 1.2rem;
}

.psemaireset > button {
    font-weight: 700;
    font-size: 16px;
    border-radius: 4px;
    background-color: var(--primarycolor);
    border: 0px;
    color: white;
    padding: 10px 0px;
    margin-top: 2.5rem;
    width: 100%;
}

.psemaireset > label {
    margin-top: 2rem;
}

.cancelicon {
    /* margin-bottom: 1rem; */
    text-align: end;
    cursor: pointer;
}

.cancelicon > svg {
    width: 50px;
}

.cancelicon > svg > path {
    stroke: var(--primarycolor);
}

.mobileinput {
    display: none;
}

.errormessageprofile {
    text-decoration: none;
    color: red;
    text-align: left;
    margin: 0px;
    font-size: 12px;
}

.successmessageprofile {
    text-decoration: none;
    color: green;
    text-align: left;
    margin: 0px;
    font-size: 12px;
}

.pfp > img {
    max-width: 100%;
}

@media only screen and (max-width: 1280px) {
    .profilesettingbox {
        box-shadow: 0px 4px 22px rgb(0 7 15 / 12%);
        border-radius: 12px;
        margin: 3rem 2rem 0rem;
    }
}

@media only screen and (max-width: 1024px) {
    .activetab {
        padding: 36px 4rem 15px;
        text-align: center;
    }

    .psinput {
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        gap: 40px;
    }

    .psinput > div {
        padding-top: 1rem;
    }

    .psinput1 {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }

    .activetab > div > form > button {
        margin-top: 2rem;
    }

    .pspassword > label {
        margin-top: 1rem;
    }

    .pspassword > button {
        margin-top: 2rem;
    }
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
    .activetab {
        padding: 36px 3rem 15px;
        text-align: center;
    }
}

@media only screen and (max-width: 600px){
    .psboxtitle > p {
        padding: 12px 6px;
        margin: 0px 6px;
        font-size: 11px !important;
    }

    .mobileinput {
        display: block;
    }

    .mobileinput-l {
        display: none;
    }

    .psinput {
        gap: 0px;
    }

    .pspassword {
        width: 70%;
    }

    .psinput-mobile {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .psboxtitle > p {
        padding: 10px 0px;
        margin: 0px 10px;
    font-size: 9px !important;
    }

    .profilesettingbox {
        margin: 3rem 0rem 0rem;
    }

    .activetab {
        padding: 36px 2rem 15px;
    }

    .interest-form {
        margin: 2.5rem auto 0;
        width: 100%;
    }
}