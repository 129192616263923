.referral-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
    justify-content: space-between;
    margin-top: 4rem;
    gap: 2rem;
}



.referral-list {
    background: #FBFAFA;
    border-radius: 12px;
    /* padding: 15px; */
}

.referral-list-title {
    display: flex;
    justify-content: space-between;
    color: #1178FF;
    font-weight: 700;
    font-size: 15px;
    border-bottom: 0.8px solid rgba(1, 25, 52, 0.19);
    padding: 15px;
}

.gen-ref-link {
    background-color: #1178FF;
    font-weight: 700;
    border: 0;
    border-radius: 4px;
    color: white;
    padding: 0.75rem 1.5rem;
}

.ref-li {
    height: 100%;
    display: flex;
    /* align-content: center; */
    align-items: center;
}

.ref-li > p {
    font-weight: 700;
    margin: auto;
}


.referral-list-title > p {
    margin: 0px;
}

.referral-list-body {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 0px;
}

.referral-list-body > p {
    font-weight: 500;
    font-size: 14px;
}

.referral-list-body > p:nth-child(2) {
    color: #00070F;
    opacity: 0.2;
}

.ref-icon {
    border: 0.5px solid #1178FF !important;
    border-radius: 12rem;
    width: 34px;
    height: 34px;
    text-align: center;
    align-content: center;
    display: grid;
    justify-content: center;
}

.ref-icon > svg, .ref-icon > svg > path {
    fill: #1178FF;
}

.share-link {
    display: flex;
    justify-content: center;
}

.share-link > div {
    background-color: #FBFAFA;
    border-radius: 76px;
    padding: 15px;
    display: flex;
    gap: 1rem;
}

.share-link > div > span {
    align-self: center;
}

.referral-link {
    box-shadow: 0px 4px 15px rgba(11, 34, 57, 0.13);
    border-radius: 12px;
    padding: 15px;
}

.referral-link > p {
    text-align: center;
    font-size: 12px;
}

.referral-link-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1.5rem 0rem;
}

.referral-link-container > p {
    border: 1px dashed rgba(0, 7, 15, 0.28);
    border-radius: 4px;
    margin: 0px;
    padding: 10px;
}

.referral-link-container > span {
    background-color: #1178FF;
    border-radius: 4px;
    padding: 10px;
}

@media only screen and (max-width: 1280px) {
  }
  
  @media only screen and (max-width: 1024px) {
    .referral-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(47%, 1fr));
    }

    .referral-link-container > p {
        border: 1px dashed rgba(0, 7, 15, 0.28);
        border-radius: 4px;
        margin: 0px;
        padding: 9px;
        font-size: 11px;
        align-self: center;
    }
    
    .share-link > div > span {
        font-size: 12px;
    }

    .referral-list-title {
        display: flex;
        justify-content: space-between;
        color: #1178FF;
        font-weight: 700;
        font-size: 12px;
    }
    
    .referral-list-body > p {
        font-weight: 500;
        font-size: 12px;
    }
    
  }
  
  @media only screen and (max-width: 992px) {
  
  }
  
  @media only screen and (max-width: 768px) {
  }
  
  @media only screen and (max-width: 600px) {
    .referral-container {
        margin-top: 1rem;
    }
  }
  
  @media only screen and (max-width: 500px) {
  }