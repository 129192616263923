.availableexamcontent {
    padding-top: 2rem;
}

.availableexamnav {
    display: flex;
    justify-content: space-between;
}

.availableexamnav > h6 {
    padding: 10px 0px;
}

.searchcontainer {
    display: flex;
}

.searchcontainer > input {
    background: #FFFFFF;
    border: 1px solid #9F9894;
    border-radius: 8px;
    width: 230px;
    font-size: 13px;
    padding: 0px 12px;
    height: 40px;
}

.searchcontainer > button {
    border: 0px;
    background: #1178FF;
    border-radius: 8px;
    padding: 0px 15px;
    margin-left: -14px;
    height: 40px;
}

.availableexamgrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 20px 20px;
    padding-top: 20px;
}

.availableexambox {
    background: #FFFFFF;
    box-shadow: 0px 4px 17px rgba(11, 34, 57, 0.1);
    border-radius: 12px;
    padding: 20px 20px 15px;
    line-height: 150%;
}

.availableexambox > h3 {
    font-size: 17px;
    font-weight: 700;
}

.availableexambox > p {
    margin-bottom: 0px;
    font-size: 15px;
}

.availableexambox > a {
    display: block;
    text-align: end;
    font-size: 12px;
    /* padding-bottom: 15px; */
}

.exam-status-card {
    position: relative;
}

.exam-status {
    position: absolute;
    top: 15px;
    right: 0rem;
    margin: 0rem;
    background-color: green;
    padding: 0px 14px;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
}

.exam-status-paid {
    background-color: orange !important;
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {
    .availableexamnav  {
        display: block;
    }
}

@media only screen and (max-width: 500px) {
    .searchcontainer > button {
        border: 0px;
        background: #1178FF;
        border-radius: 0px;
        padding: 0px 10px;
        height: 30px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .searchcontainer > input {
        border-radius: 8px;
        width: 115px;
        font-size: 13px;
        height: 30px;
    }

    .searchcontainer > button > img {
        max-width: 80%;
    }

    .pageli, .breakli {
        padding: 4px 5px;
        font-size: 12px;
    }

    .previous, .next {
        padding: 1px 5px;
    }

    .prevbtn > svg, .nextbtn > svg {
        height: 10px;
    }

    .selectboxmobile {
    border-top-left-radius: 0px !important; 
     border-bottom-left-radius: 0px !important; 
    }

    .searchbutton1 {
    border-radius: 0px !important;
    }
}

