.login-input > input {
    height: 40px;
}

.forgetpassword {
    float: right;
    font-size: 12px;
    color: var(--primarycolor);
}

.social-button {
    padding: 0.5rem 0rem;
}

.social-button > button {
    margin: 0px !important;
    width: calc(100% - 0%) !important;
}