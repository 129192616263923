:root {
  --primarycolor: #1178FF;
  --secondarycolor: #002099;
  --fontfamily: 'Mulish';
  --secondaryfontfamily: 'Clash Display Variable';
  font-display: swap
}

p {
  font-family: var(--fontfamily);
  font-display: swap
}
a {
  font-family: var(--fontfamily);
  font-display: swap
}
div {
  font-family: var(--fontfamily);
  font-display: swap
}
span {
  font-family: var(--fontfamily);
  font-display: swap
}
