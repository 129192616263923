.contact-container {
    padding: 1rem 6.2rem 0rem 5rem;
}

.hcwhy {
    background-color: var(--secondarycolor);
    color: white;
}

.hcwhy > div {
    padding: 7rem 4rem 7rem;
}

.hcwhy > div > h3 {
    font-family: var(--secondaryfontfamily);
    font-size: 40px;
    padding: 10px 0px;
}

.contact-info > h5 {
    font-size: 30px;
    font-weight: 700;
}

.contact-info > div > div > span {
    vertical-align: middle;
    padding-left: 15px;
    color: #002099;
}

.contact-info > div > div {
    margin: 10px 0px 20px 0px;
}

.contact-info > div > div > a {
    padding-right: 5px;
}

.contact-info > div {
    border-left: 5px solid #002099;;
    padding-left: 3.5rem;
    margin-top: 2.5rem;
}

.contact-info > div > h6 {
    font-size: 20px;
    font-weight: 700;
}

.contact-info > div > p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
}

.contact-form > div > div {
    padding-left: 0px;
}

.contact-form > div > div > input {
    border-bottom: 1px solid #DDDDDD !important;
    border: 0px;
    border-radius: 0px;
}

.contact-form > div > div > input:focus-visible {
    border-bottom: 1px solid #1178FF !important;
    border: 0px;
    border-radius: 0px;
    outline: none;
}

.contact-form > div > input {
    border-bottom: 1px solid #DDDDDD !important;
    border: 0px;
    border-radius: 0px;
}

.contact-form > div > input:focus-visible {
    border-bottom: 1px solid #1178FF !important;
    border: inherit;
    border-radius: 0px;
    outline: none;
}

.contact-form > div > textarea {
    border-bottom: 1px solid #DDDDDD !important;
    border: 0px;
    border-radius: 0px;
    width: 100%;
}

.contact-form > div > textarea:focus-visible {
    border-bottom: 1px solid #1178FF !important;
    border: 0px;
    border-radius: 0px;
    width: 100%;
    outline: none;
}

textarea::placeholder{
    color: #DDDDDD;
    vertical-align: bottom;
}

.contact-form > div > div > label {
    font-weight: 600;
}

.contact-form > div > label {
    font-weight: 600;
}

.contact-form > button {
    background-color: var(--secondarycolor);
    color: white;
    align-items: center;
    vertical-align: middle;
    font-size: 14px;
    border: 0px;
    padding: 10px;
    font-weight: 600;
    margin-top: 4rem;
}

.contact-form > button > img {
    padding-left: 10px;
}

.contact-details {
    margin-top: 6rem !important;
}

.nsh {
    margin-top: 4rem !important;
}

.nsh > div {
    background-color: black;
    color: white;
    margin: 0rem 1.75rem;
    padding: 3rem;
}

@media only screen and (max-width: 1280px) {
    .contact-container {
        padding: 1rem 2rem 0rem 2rem;
    }
    
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 992px) {
    .contact-container {
        padding: 1rem 1rem 0rem 1rem;
    }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 600px) {
    .contact-container{
        padding: 0px;
    }

    .hcwhy > div {
        padding: 4rem 2rem 4rem;
    }

    .contact-details {
        padding: 0px 1.5rem;
        margin-top: 4rem !important;
    }

    .contact-form {
        box-shadow: 0px 4px 17px rgb(11 34 57 / 10%);
        border-radius: 12px;
        padding: 25px 25px;
        margin-top: 2rem !important;
    }

    .contact-form > button {
        margin-top: 2rem;
    }

    .nsh {
        margin: 4rem 10px 0rem !important;
    }

    .nsh > div {
        margin: 0rem 0.75rem;
        padding: 2rem 1.5rem;
    }
}

@media only screen and (max-width: 500px) {
    .contact-form > div > div {
        padding-top: 1rem!important;
    }
    
    .contact-details {
        padding: 0px 0.75rem;
    }    

    .nsh > div {
        margin: 0rem 0.75rem;
        padding: 2rem 1rem;
    }
}