.transactionhistorycontent{
    margin-top: 6rem;
}

.transactionhistorytable {
    box-shadow: 0px 4px 15px rgba(11, 34, 57, 0.13);
    border-radius: 8px;
    /* background-color: var(--secondarycolor); */
    color: white;
    border-collapse: collapse;
    overflow: hidden;
}

.transactionhistorytable > thead {
    background-color: var(--secondarycolor);
    color: white;
    border-radius: 8px;
    overflow: hidden;
}
.transactionhistorytable > tbody {
    color: #00070F;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
}

.success-color {
    color: #198754;
}

.pending-color {
    color: #002099;
}

.no-history {
    text-align: center;
    padding: 7rem 0rem
}

.transactionhistorytable > tbody > tr {
    background-color: rgba(205, 219, 227, 0.1);
    border-top: 30px solid white;
    border-bottom: 30px solid white;
}

td:first-child, th:first-child  {
    padding-left: 40px !important;
}

th, td {
    padding: 15px !important;
}


@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {
    .transactionhistorytable > tbody {
        font-size: 11px;
    }

    th, td {
        padding: 10px !important;
    }

    td:first-child, th:first-child {
        padding-left: 25px !important;
    }

    th, td {
        font-size: 10px;
    }
}

@media only screen and (max-width: 500px) {
    th, td {
        padding: 0px 5px !important;
        font-size: 7px;
    }

    .transactionhistorytable > tbody > tr {
        background-color: rgba(205, 219, 227, 0.1);
        border-top: 11px solid white;
        border-bottom: 11px solid white;
    }

    .transactionhistorycontent {
        margin-left: -15px;
        margin-right: -15px;
    }

    td:first-child, th:first-child {
        padding-left: 12px !important;
    }

    .transaction-header > th {
        padding: 7px !important;
    }
}
