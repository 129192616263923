.examhistorycontent {
    padding-top: 3rem;
}

.examhistorynav {
    display: flex;
    justify-content: space-between;
}

.examhistorynav > h6 {
    padding: 10px 0px;
}

.performance-coming-soon {
    background-color: #9E9E9E !important;
}

.examhistorygrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 20px 20px;
    padding-top: 20px;
}

.examhistorybox {
    background: #FFFFFF;
    box-shadow: 0px 4px 17px rgba(11, 34, 57, 0.1);
    border-radius: 12px;
    text-align: center;
    padding: 30px 20px;
}

.examhistorybox > h5 {
    font-weight: 700;
    font-size: 18px;
    text-align: left;
}

.examhistorybox > div {
    display: flex;
    justify-content: space-between;
}

.examhistorybox > div > p > span {
    font-size: 14px;
    font-weight: 600;
}

.examhistorybox > p {
    text-align: left;
    padding-bottom: 16px;
}

.examhistorybox > p > span {
    color: #00BB29;
    font-weight: 600;
}

.examhistorybox > div > a {
    border-radius: 12px;
    border: 0px;
    font-weight: 700;
    font-size: 13px;
    background-color: #1178FF;
    text-decoration: none;
    color: white;
    padding: 10px 15px;
}

.examhistorybox > a:hover {
    color: white;
}

.paginate {
    display: flex;
    justify-content: space-between;
}



@media only screen and (max-width: 600px) {
   .examhistorynav {
    display: block;
   }

}
