.subscriptioncontent > div {
    padding: 15px 0px;
}

.subscriptiionbox {
    box-shadow: 0px 4px 20px rgb(11 34 57 / 12%);
    border-radius: 8px;
    padding: 20px 20px 20px 20px;
    position: relative;
    height: 165px;
    width: 250px;
}

.subscriptiionbox > h6 {
    font-weight: 700;
    font-size: 16px;
}

.subscriptiionbox > span > p {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0px;
}

.subscriptiionbox > span {
    font-weight: 700;
    font-size: 20px;
    color: rgba(0, 7, 15, 0.45);
    position: absolute;
    bottom: 0;
    margin-bottom: 15px;
}

.subscriptiionbox > span > a {
    font-weight: 400;
    font-size: 12px;
}

.subscriptionboxplan {
    border: 1px solid rgba(40, 40, 40, 0.4);
    border-radius: 12px;
    text-align: center;
    padding: 25px 15px;
}

.subscriptionboxplangrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: 20px;
    padding-top: 30px;
}

.subscriptionboxplan > div > h6 {
    font-weight: 600;
    font-size: 20px;
    color: rgba(0, 7, 15, 0.9);
    margin: 0px;
}

 .subscriptionboxplan > div > span {
    font-weight: 400;
font-size: 12px;
color: rgba(0, 7, 15, 0.35);
}

.subscriptionboxplan > div {
    text-align: left;
    padding: 10px 0px;
}

.subscriptionboxplan > div > h5 {
    font-weight: 800;
    font-size: 26px;
    color: rgba(0, 7, 15, 0.9);
    margin: 0px;
}

.subscriptionboxplan > button {
    font-weight: 800;
    font-size: 16px;
    color: white;
    background-color: var(--primarycolor);
    border-radius: 8px;
    border: 0px;
    padding: 9px 35px;
    margin-top: 20px;
}

.subscriptionboxplan > div > svg > .first {
    fill: #1178FF;
}

.subscriptionboxplan > div > svg > .second {
    fill: #1178FF;
}

.activesubplan {
    border: 1px solid rgba(40, 40, 40, 0.4);
    border-radius: 12px;
    text-align: center;
    padding: 25px 15px;
    background-color: #1178FF;
}

.activesubplan > div > svg > .first {
    fill: #00070F;
}

.activesubplan > div > svg > .second {
    fill: #00070F;
}

.activesubplan > button {
    font-weight: 800;
    font-size: 16px;
    color: white;
    background-color: var(white);
    border-radius: 8px;
    border: 0px;
    padding: 9px 35px;
    margin-top: 20px;
}

.activesubplan > div > h6 {
    font-weight: 600;
    font-size: 20px;
    color: white;
    margin: 0px;
}

 .activesubplan > div > span {
    font-weight: 400;
font-size: 12px;
color: rgba(255, 255, 255, 0.49);
}

.activesubplan > div {
    text-align: left;
    padding: 10px 0px;
}

.activesubplan > div > h5 {
    font-weight: 800;
    font-size: 26px;
    color: white;
    margin: 0px;
}