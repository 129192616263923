.termsandcondition {
    padding: 3rem 15rem 1rem;
    height: 100vh;
}

.termsandcondition > h1 {
    padding-bottom: 2rem;
}

@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 1024px) {
    .termsandcondition {
        padding: 3rem 9rem 1rem;
    }
}

@media only screen and (max-width: 992px) {
    
}

@media only screen and (max-width: 768px) {
    .termsandcondition {
        padding: 3rem 6rem 1rem;
    }
}

@media only screen and (max-width: 600px) {
    .termsandcondition {
        padding: 3rem 3rem 1rem;
    }
}

@media only screen and (max-width: 500px) {
    .termsandcondition {
        padding: 2rem 2rem 1rem;
        font-size: 12px;
    }

    .termsback {
        padding-bottom: 1rem;
    }

    .termsandcondition > h1 {
        padding-bottom: 1rem;
    }

    .terms-container {
        padding: 0rem 0rem 2rem;
    }
}