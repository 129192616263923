body {
  margin: 0;
  font-family: Mulish ,system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap
}

code {
  font-family: var(--fontfamily);
}

@font-face {
  font-family: 'Mulish';
  src: local('Mulish'), url(./assets/fonts/Mulish/Mulish-VariableFont_wght.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Clash Display Variable';
  src: local('Clash Display Variable'), url(./assets/fonts/ClashDisplayVariable/WEB/fonts/ClashDisplay-Medium.ttf) format('truetype');
  font-display: swap;
}

[class*="row"],
[class^="row"] {
  margin: 0px !important;
}

body {
  margin: 0;
  font-family: Mulish ,system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap
}

code {
  font-family: var(--fontfamily);
}

@font-face {
  font-family: 'Mulish';
  src: local('Mulish'), url(./assets/fonts/Mulish/Mulish-VariableFont_wght.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Clash Display Variable';
  src: local('Clash Display Variable'), url(./assets/fonts/ClashDisplayVariable/WEB/fonts/ClashDisplay-Medium.ttf) format('truetype');
  font-display: swap;
}

[class*="row"],
[class^="row"] {
  margin: 0px !important;
}

