.question-pagination > button {
    border: 0px;
    padding: 10px 3rem;
    border-radius: 8px;
    color: white;
    background-color: blue;
    font-weight: 700;
    margin: 1rem 2.5rem;
}

.question-asked > span, .question-asked > p {
    font-family: 'Mulish' !important;
}

.question-pagination {
    display: flex;
    justify-content: space-between;
    margin: 2.5rem 0rem;
}

.questionbox {
    background: #FFFFFF;
    box-shadow: 0px 4px 17px rgb(11 34 57 / 10%);
    border-radius: 12px;
    text-align: left;
    padding: 30px 40px;
}

.question-option {
    display: block;
}

.each-option {
    display: flex;
}

.result-performance {
    display: flex;
    justify-content: center;
    margin: 1rem;
}

.result-performance > a {
    border-radius: 12px;
    border: 0px;
    font-weight: 700;
    font-size: 13px;
    background-color: #1178FF;
    text-decoration: none;
    color: white;
    padding: 10px 15px;
}

.each-option > input {
    width: fit-content;
}

.each-option > label {
    margin: auto 0rem;
    padding: 0rem 0.5rem;
}

.question-container {
    margin: 1rem 10rem;
}

.header-container {
    border-bottom: 0.7px solid #CDCDCD;
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
}

.question-instruction {
    margin: 4rem 0rem 2rem;
}

.question-instruction > p {
    font-size: 13px;
    font-weight: 300;
}

.question-back-button {
    background-color: var(--secondarycolor);
    border-radius: 8px;
    padding: 0.5rem 2rem;
    width: fit-content;
    color: white;
    font-weight: 700;
    cursor: pointer;
}

.confirm-modal > .modal-dialog  {
    width: 400px;
}

.No-button {
    background-color: white;
    color: #0d6efd;
}

.cancel-button {
    background-color: white !important;
    color: red !important;
    border: 1px solid red !important;
}

.cancel-button:hover{
    background-color: red !important;
    color: white !important;
}

.start-exam {
    display: flex;
    justify-content: space-between;
}

.question-modal {
    box-shadow: 0px 4px 20px rgba(0, 7, 15, 0.1);
    border-radius: 12px;
    width: 500px;
    padding: 1.5rem;
    margin: 15rem auto;
}

.exam-instruction {
    font-size: 14px;
    margin: 1rem 0rem;
}

.no_question{
    margin: 12rem 0rem;
    font-weight: 700;
}

.upgrade-error {
    text-align: center;
}

.upgrade-error > svg {
    width: 64px;
    height: 64px;
}

.exam-upgrade {
    text-align: center;
    font-size: 18px;
    padding: 15px;
    font-weight: 700;
}

.exam-upgrade-button {
    text-decoration: none;
    padding: 10px 15px;
    display: block;
    height: 37px !important;
    margin: auto;
    text-transform: uppercase;
}

.exam-upgrade-button:hover {
    color: white;
}

.start-button {
    background: #1178FF;
    border-radius: 6px;
    border: 0px;
    color: white;
    padding: 5px 10px;
    font-weight: 600;
    width: 115px;
    height: 45px;
}

/* .exam-page {
    height: 100vh;
} */

.submitmodal > .modal-dialog {
    width: 525px;
}

.exam-submit > svg {
    width: 300px;
    height: 250px;
}

.exam-submit > h5 {
    font-weight: 700;
    font-size: 25px;
    color: #00A82F;
}

.exam-submit > p {
    font-weight: 400;
    font-size: 16px;
    color: rgba(1, 7, 31, 0.69);;
}

.submit-table-container {
    background: #FFFFFF;
    border: 1px solid rgba(17, 120, 255, 0.1);
    border-radius: 8px;
}

.submit-table {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: left;
}

.submit-title {
    font-weight: 700 !important;
    font-size: 23px !important;
}

.colored-submit-table {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: left;
    background-color: #E9ECF3;
    border-top: 1px solid #003DC9;
    border-bottom: 1px solid #003DC9;
}

.submit-table > p, .colored-submit-table > p {
    margin-bottom: 0px;
    padding: 0.75rem 1.5rem;
}

.submit-table-border {
    border-right: 1px dashed rgba(0, 32, 153, 0.39); ;
}

.result-title {
    font-size: 15px;
}

.exam-title {
    font-weight: 600;
}

.sub-con {
    font-weight: 800;
    font-size: 18px;
    color: #00BB29;
}

.click {
    border-radius: 6px;
background-color: #00070F;
color: white;
border: 0;
padding: 10px 15px;
}


.question-progress {
    display: flex;
    background-color: #FFF27A;
    border: 1px solid #00070F;
    border-radius: 12px;
    color: black;
    padding: 2rem 2rem;
    }

    .question-progress > div:nth-child(1) {
        width: 40%;
    }

    .question-progress > div:nth-child(2) {
        width: 60%;
    }

    .question-progress > div > div {
        padding-bottom: 1rem;
        display: flex;
    }

    .question-progress-info {
        padding-left: 15px;
    }

.question-progress-info > p:nth-child(1) {
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 0.5rem;
}

.question-progress-info > p:nth-child(2) {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 0.5rem;
}

@media only screen and (max-width: 1280px) {
    .question-modal {
        margin: 17rem auto;
    }
}

@media only screen and (max-width: 1024px) {
    .question-container {
        margin: 1rem 5rem;
    }

    .header-container {
        margin-top: 3rem;
    }

    .question-instruction {
        margin: 2rem 0rem;
    }

    .question-modal {
        margin: 11rem auto;
    }
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {
    .question-container {
        margin: 1rem 2.5rem;
    }

    .questionbox {
        background: #FFFFFF;
        box-shadow: 0px 4px 17px rgb(11 34 57 / 10%);
        border-radius: 12px;
        text-align: left;
        padding: 25px 20px;
    }

    .question-option {
        font-size: 19px;
        margin-top: 1rem;
    }

    .header-container {
        margin-top: 3rem;
        font-size: 15px;
    }

    .question-modal {
        width: 450px;
    }    
}

@media only screen and (max-width: 500px) {
    .question-container {
        margin: 1rem 1rem;
    }

    .header-container {
        margin-top: 3rem;
        font-size: 10px;
    }

    .header-container > p {
        margin-bottom: 0.5rem;
    }

    .questionbox {
        font-size: 13px;
    }

    .question-pagination > button {
        margin: 1rem 0.5rem;
    }

    .each-option > label {
        font-size: 12px;
    }

    .question-modal {
        width: 355px;
    }
}