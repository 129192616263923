.dashboardlayout {
    display: grid;
    width: 100vw;
    grid-template-columns: 250px auto;
    height: 100vh;
}

.sidbarcollapse {
    display: grid;
    width: 100vw;
    grid-template-columns: 50px auto;
    height: 100vh;
}

.sidbarcollapse > .sidebar > div > svg {
    display: none;
}

.sidebar {
    background-color: rgba(17, 120, 255, 0.07);
    height: 100vh;
    position: relative;
    border-right: 1px solid #002099;
}

.sidebar > div {
    padding: 20px 20px 20px 0px;
}

.sidebarlist {
    padding: 20px 8px 20px 0px !important;
}

.sidebar > div > span {
    float: right;
    cursor: pointer;
}

.sidebar > div > span > svg > path {
    fill: #002099;
}

.sidebarname {
    text-decoration: none !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    color: rgba(0, 32, 153, 0.56);;
}

.sidebarname:hover {
    color: #002099;
}

.sidebarname:hover > .dashboardiconclass {
    fill: #002099;
}

.dropdown-link {
    color: black;
    text-decoration: none;
}

.dropdown-link:hover {
    color: black;
    text-decoration: none;
}

.dashboardiconclass {
    fill: rgba(0, 32, 153, 0.56);;
}
.sidebarname > span {
    vertical-align: middle;
    padding-left: 15px;
}

.dropdownbox > span {
    display: none;
}

.dropdownbox > .dropdownicon {
    display: none;
}


.active {
    border-left: 2px solid white;
}

.active > .sidebarname, .active > .sidebarname > .dashboardiconclass, .active > .dropdownbox > .dashboardiconclass {
    color: #002099;
    fill: #002099;
}

.active > .sidebarname > .dashboardiconclass > path {
    stroke: inherit;
}

.nav-link {
    padding: 5px 0rem 0px 2rem !important;
    margin: 12px 0px;
    cursor: pointer;
}

.nodropdownmenu {
    visibility: hidden;
    transition: height 0.5s ease;
}

.check {
    /* display: none; */
    height: 0px;
}

.dropdownmenu {
    transition: height 0.5s ease;
    height: 75px;
    overflow: hidden;
}

.dropdownmenu2 {
    height: 155px;
}

.dropdownmenu > div > a{
    padding-left: 30px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    display: block;
    color: rgba(0, 32, 153, 0.56);;
}

.dropdownmenu > div > a:hover {
    color: #002099;
}

.dropdownmenutab {
    border-left: 0px;
    margin: 0px 0px 10px;
}

.dropdownmenutab1 > a {
    padding-left: 15px !important;
    border-left: 0px;
    margin: 0px 0px 10px;
}

.dropdownmenu > .active > a {
 color: #002099;
}

.praticemenu {
 color: white;
}

.sidbarcollapse > div > div > div > .dropdownmenu {
    display: none;
}

.logoutbox {
    position: absolute;
    bottom: 15px;
}

.logoutbox > a {
    padding: 5px 1rem 0px 2rem !important;
}

.sidbarcollapse > .sidebar > div > .nav > .nav-link, .sidbarcollapse > .sidebar > .logoutbox > a {
    padding: 5px 1rem !important;
}

.maincontainer, .maincontainerexpand {
    background-color: white;
    padding: 2rem 4rem 2rem;
    overflow: auto;
}

.dashnav {
    display: flex;
    justify-content: space-between;
}

.dashnavaccount {
    display: flex;
    box-shadow: 0px 0px 5px 0px rgb(0 7 15 / 10%);
    border-radius: 8px;
    padding: 0px 14px;
}

.dashnav > h5 {
    font-size: 25px;
    font-weight: 700;
    text-transform: capitalize;
    
}

.dashnavaccount > p {
    font-size: 12px;
    margin: 0px;
    font-weight: 600;
    padding: 2px;
}
.dashnavaccount > div {
    padding-left: 15px;
    margin: auto;
}

.dashnavaccount > div > button {
    background-color: inherit;
    border: 0px;
    color: black;
    /* padding: 0px; */
    vertical-align: middle;
    font-size: 15px;
    font-weight: 600;
}

.dashnavaccount > div > button:hover {
    background-color: inherit;
    color: black;
}

.mobilenavbar {
    display: none;
}

.mobilesidebar {
    display: none;
}

.navprofile:active{
    background-color: white !important;
    color: black !important;
}

.dashboardiconclass > path {
    stroke: rgba(255, 255, 255, 0.6);
}

.dashboardiconclass2 > path {
    fill: rgba(0, 32, 153, 0.56);
    fill-opacity: unset;
}

.dropdownicon {
    margin-left: 15px;
}

.logoutbox > a {
    color: rgba(254, 33, 33, 0.84);
}

.logoutbox > a > svg {
    fill: rgba(254, 33, 33, 0.84);;
}


@media only screen and (max-width: 1280px) {
   
}

@media only screen and (max-width: 1024px) {
    .dashboardlayout {
        display: grid;
        width: 100vw;
        grid-template-columns: 200px auto;
        height: 100vh;
    }

    .sidebarname {
        text-decoration: none !important;
        font-size: 13px !important;
        color: white;
    }

    .dashboardiconclass {
        fill: white;
    }

    .dashboardiconclass > path {
        stroke: white;
        fill: white;
    }

        .dropdownmenu > div > a {
        font-size: 13px;
    }

    .nav-link {
        padding: 5px 0rem 0px 2rem !important;
        margin: 5px 0px;
    }

    .dropdownmenutab1 > a {
        padding-left: 3px !important;
    }

    .content {
        padding-bottom: 1.5rem;
    }
}

@media only screen and (max-width: 992px) {
    .dashboardlayout, .sidbarcollapse {
        display: block;
    }

    .sidebar {
        display: none;
    }

    .mobilenavbar {
        display: flex;
        /* justify-content: space-between;
        border-bottom: 1px solid var(--primarycolor); */
    }

    .sn {
        color: white !important;
    }
    .mobilenavbar > h5 {
        font-size: 25px;
        font-weight: 700;
        text-transform: capitalize;
        padding-left: 15px;
    }

    .mobilenavbar > span > svg {
        fill: var(--primarycolor);
        width: 25px;
        height: 35px;
    }

    .dashnav {
        padding: 10px 20px;
        padding: 10px 20px;
        box-shadow: 0px 4px 17px rgb(163 194 235);
    }

    .dashnav > h5 {
        display: none;
    }

    .maincontainer, .maincontainerexpand {
        padding: 0rem;
    }

    .nav-link {
        padding: 5px 0rem 0px 2rem !important;
        margin: 10px 0px;
    }

    .sidebarname {
        text-decoration: none !important;
        font-size: 18px !important;
    }

    .active {
        border-left: 0px solid white;
    }

    .offcanvas {
        background-color: var(--primarycolor) !important;
    }

    .offcanvas.offcanvas-start {
        width: 300px !important;
    }

    .maincontainer, .maincontainerexpand {
        overflow: inherit;
    }

    .content {
        padding: 2rem 4rem 1rem;
    }
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {
    .content {
        padding: 2rem 2rem 0rem;
    }
    .dashnavaccount > p {
        display: none;
    }

    .check {
        /* display: none; */
        height: 0px;
    }

    .dropdownmenu {
        transition: height 0.5s ease;
        height: 75px;
        overflow: hidden;
    }

    .dropdownmenu2 {
        height: 155px;
    }

}

@media only screen and (max-width: 500px) {
    .offcanvas-backdrop.show {
        opacity: unset;
    }

    .offcanvas.offcanvas-start {
        top: 50px;
    }

    .mobilenavbar > h5 {
        font-size: 15px;
        padding-top: 4px;
        margin-bottom: 0px;
    }

    .dashnavaccount > p {
        display: none;
    }

    .navprofile:active{
        background-color: white !important;
        color: black !important;
    }

    .mobilenavbar > span > svg {
        fill: var(--primarycolor);
        width: 22px;
        height: 25px;
    }

    .dashnavaccount > div {
        padding-left: 0px;
    }

    .mobilenavbar {
        display: flex;
        padding: 6px 0px;

    }

    .searchcontainer > button {
        border: 0px;
        background: #1178FF;
        padding: 0px 10px;
        height: 30px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .searchcontainer > input {
        border-radius: 8px;
        width: 115px;
        font-size: 13px;
        height: 30px;
    }

    .content {
        padding: 2rem 2rem 0rem;
    }

    .dashnavaccount {
        padding: 6px 5px;
    }

    .mobilenavbar > h5 {
        font-size: 13px;
    }

    .dropdownmenu > div > a {
        font-size: 16px;
    }
    
    
}